import dateAtNoon from "./dateAtNoon";

export default function parseDateOrNull(dateStr) {
  const dateMs = Date.parse(dateStr);
  if (isNaN(dateMs)) {
    return null;
  } else {
    return dateAtNoon(new Date(dateMs));
  }
}
