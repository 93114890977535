import React from "react";
import { Well, FormControl } from "react-bootstrap";
import CurrencyCodeChooser from "../../../Utilities/Components/CurrencyCodeChooser";

class SalesMappingStep11g extends React.Component {
  render() {
    const { currencyInFile, conversionRate } = this.props;

    if (currencyInFile === true && conversionRate === false) {
      return (
        <Well>
          <p>What is the <b>target currency</b>?</p>

          <CurrencyCodeChooser
            blank="Select a currency code"
            value={this.props.value || ""}
            onChange={this.props.setTargetCurrencyCode}
          />
        </Well>
      );
    }

    return null;
  }
}

export default SalesMappingStep11g;
