import React from 'react'
import { Table, Button, DatePicker, Select} from 'antd';
import Icon, { ReadOutlined, DownloadOutlined } from '@ant-design/icons';
import { Alert, Well } from "react-bootstrap";
import $ from "jquery";
import moment from 'moment';

const MANUAL_CONVERSION_RATE = 'MANUAL';

class SalesDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      revenues: [],
      revenue_files: [],
      loading: true,
      startValue: null,
      endValue: null,
      startDate: null,
      endDate: null,
      error: null,
      revenueFileUUID: this.props.revenueFileUUID || "ALL",
    };

    this.disabledStartDate = this.disabledStartDate.bind(this);
    this.disabledEndDate = this.disabledEndDate.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onStartChange = this.onStartChange.bind(this);
    this.onEndChange = this.onEndChange.bind(this);
    this.handleStartOpenChange = this.handleStartOpenChange.bind(this);
    this.handleEndOpenChange = this.handleEndOpenChange.bind(this);
    this.retrieveSales = this.retrieveSales.bind(this);
    this.setRevenueFile = this.setRevenueFile.bind(this);
    this.expandIcon = this.expandIcon.bind(this);
    this.parseFailureStatus = this.parseFailureStatus.bind(this);
    this.blankToDashes = this.blankToDashes.bind(this);
    this.formatCurrency = this.formatCurrency.bind(this);
    this.formatUnits = this.formatUnits.bind(this);
    this.formatDate = this.formatDate.bind(this);
  }

  componentWillMount() {
    this.retrieveSales();
  }

  retrieveSales(){
    const {revenueFileUUID, startDate, endDate} = this.state;
    const { revenue_files }= this.props;

    if(revenue_files.length == 0){
      this.setState({
        loading: false
      });
      return;
    }

    this.setState({
      loading: true
    });

    $.ajax({
      url: "/revenues.json?revenue_file_uuid=" + revenueFileUUID + "&start_date="+ startDate + "&end_date=" + endDate,
      type: "get",
      statusCode: {
        200: (response) => {
          this.setState({
            revenues: response,
            error: null,
            loading: false
          });
        },
        422: (response) => {
          const error = response.responseJSON.error;
          this.setState({
            loading: false,
            error: error
          });
        }
      }
    });
  }

  blankToDashes(value) {
    if (value === null || value === "") {
      return "---";
    }

    return value;
  }

  formatCurrency(value, currency) {
    if (value === null) {
      return "---";
    }

    return value.toLocaleString('en-US', { style: 'currency', currency: (currency && currency !== MANUAL_CONVERSION_RATE ? currency : 'USD') });
  }

  formatUnits(value) {
    if (value === null) {
      return "---";
    }

    return value.toLocaleString('en-US');
  }

  formatDate(value) {
    if (value === null) {
      return "---";
    }

    return moment(value).format('l')
  }

  pushRow(rows, revenue){
    rows.push({key: revenue.uuid,
      project: this.blankToDashes(revenue.project),
      author_name: this.blankToDashes(revenue.author_name),
      product_id: this.blankToDashes(revenue.product_id),
      transaction_date: this.formatDate(revenue.transaction_date),
      gross_units_sold: this.formatUnits(revenue.gross_units_sold),
      units_returned: this.formatUnits(revenue.units_returned),
      net_units_sold: this.formatUnits(revenue.net_units_sold),
      list_price: this.formatCurrency(revenue.list_price),
      net_amount: this.formatCurrency(revenue.net_amount),
      discount_amount: this.formatCurrency(revenue.discount_amount),
      discount_rate: this.blankToDashes(revenue.discount_rate),
      payout_plan: this.blankToDashes(revenue.payout_plan),
      mfg_cost: this.formatCurrency(revenue.mfg_cost),
      revenue_type_description: this.blankToDashes(revenue.revenue_type_description),
      format: this.blankToDashes(revenue.format),
      file_name: this.blankToDashes(revenue.file_name),
      status: this.blankToDashes(revenue.status),
      import_date: this.formatDate(revenue.created_at),
      original_net_amount: this.formatCurrency(revenue.original_net_amount, revenue.currency_code_from),
      original_list_price: this.formatCurrency(revenue.original_list_price, revenue.currency_code_from),
      original_discount_amount: this.formatCurrency(revenue.original_discount_amount, revenue.currency_code_from),
      currency_code_from: this.blankToDashes(revenue.currency_code_from),
      currency_code_to: this.blankToDashes(revenue.currency_code_to),
      currency_date: this.formatDate(revenue.currency_date),
      currency_rate: this.blankToDashes(revenue.currency_rate),
      buyer: this.blankToDashes(revenue.buyer),
      territory: this.blankToDashes(revenue.territory),
      account_name: this.blankToDashes(revenue.account_name),
      invoice_number: this.blankToDashes(revenue.invoice_number),
      line_number: this.blankToDashes(revenue.line_number),
      deduction_amount: this.blankToDashes(revenue.deduction_amount),
      misc_alpha_1: this.blankToDashes(revenue.misc_alpha_1),
      misc_alpha_2: this.blankToDashes(revenue.misc_alpha_2),
      misc_alpha_3: this.blankToDashes(revenue.misc_alpha_3),
      misc_number_1: this.blankToDashes(revenue.misc_number_1),
      misc_number_2: this.blankToDashes(revenue.misc_number_2),
      failure_status: this.blankToDashes(revenue.failure_status),
      canExpanded: ( revenue.status === "Success" ? false : true),
    });
  }

  expandIcon({ expanded, expandable, record, onExpand }) {
    if (!expandable || record.canExpanded === false) return null;

    return (
      <a onClick={e => onExpand(record, e)}>
        {expanded ? <Icon type="minus-square" /> : <Icon type="plus-square" />}
      </a>
    );
  }

  renderTable() {
    const { revenues } = this.state;

    let dataSource = [];
    revenues.map(revenue => this.pushRow(dataSource, revenue));

    const uniqueProductIDs = [...new Set(dataSource.map(sale => sale.product_id))];

    let productFilters = [];
    uniqueProductIDs.map(productID => productFilters.push({text: productID, value: productID}));

    const uniqueProjects = [...new Set(dataSource.map(sale => sale.project))];

    let projectFilters = [];
    uniqueProjects.map(project => projectFilters.push({text: project, value: project}));

    const uniqueAuthors = [...new Set(dataSource.map(sale => sale.author_name))];

    let authorFilters = [];
    uniqueAuthors.map(author_name => authorFilters.push({text: author_name, value: author_name}));

    const uniqueFiles = [...new Set(dataSource.map(sale => sale.file_name))];

    let fileFilters = [];
    uniqueFiles.map(file_name => fileFilters.push({text: file_name, value: file_name}));

    const uniqueStatuses = [...new Set(dataSource.map(sale => sale.status))];

    let statusFilters = [];
    uniqueStatuses.map(status => statusFilters.push({ text: status, value: status }));

    const columns = [
      {
        title: 'Transaction Date',
        dataIndex: 'transaction_date',
        key: 'transaction_date',
      },
      {
        title: 'Product ID',
        dataIndex: 'product_id',
        key: 'product_id',
        filters: productFilters,
        onFilter: (value, record) => record.product_id.indexOf(value) === 0,
        sorter: (a, b) => a.product_id.localeCompare(b.product_id),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Project',
        dataIndex: 'project',
        key: 'project',
        filters: projectFilters,
        onFilter: (value, record) => record.project.indexOf(value) === 0,
        sorter: (a, b) => a.project.localeCompare(b.project),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Author Name',
        dataIndex: 'author_name',
        key: 'author_name',
        filters: authorFilters,
        onFilter: (value, record) => record.author_name.indexOf(value) === 0,
        sorter: (a, b) => a.author_name.localeCompare(b.author_name),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Gross Units Sold',
        dataIndex: 'gross_units_sold',
        key: 'gross_units_sold',
      },
      {
        title: 'Units Returned',
        dataIndex: 'units_returned',
        key: 'units_returned',
      },
      {
        title: 'Net Units Sold',
        dataIndex: 'net_units_sold',
        key: 'net_units_sold',
      },
      {
        title: 'List Price',
        dataIndex: 'list_price',
        key: 'lists_price',
      },
      {
        title: 'Net Amount',
        dataIndex: 'net_amount',
        key: 'net_amount',
      },
      {
        title: 'Discount Amount',
        dataIndex: 'discount_amount',
        key: 'discount_amount',
      },
      {
        title: 'Discount Rate',
        dataIndex: 'discount_rate',
        key: 'discount_rate',
      },
      {
        title: 'Deduction Amount',
        dataIndex: 'deduction_amount',
        key: 'deduction_amount',
      },
      {
        title: 'Payout Plan',
        dataIndex: 'payout_plan',
        key: 'payout_plan',
      },
      {
        title: 'Mfg Cost',
        dataIndex: 'mfg_cost',
        key: 'mfg_cost',
      },
      {
        title: 'Revenue Type',
        dataIndex: 'revenue_type_description',
        key: 'revenue_type_description',
      },
      {
        title: 'Format',
        dataIndex: 'format',
        key: 'format',
      },
      {
        title: 'Original Net Amount',
        dataIndex: 'original_net_amount',
        key: 'original_net_amount',
      },
      {
        title: 'Original List Price',
        dataIndex: 'original_list_price',
        key: 'original_list_price',
      },
      {
        title: 'Original Discount Amount',
        dataIndex: 'original_discount_amount',
        key: 'original_discount_amount',
      },
      {
        title: 'Original Currency',
        dataIndex: 'currency_code_from',
        key: 'currency_code_from',
      },
      {
        title: 'Currency Converted To',
        dataIndex: 'currency_code_to',
        key: 'currency_code_to',
      },
      {
        title: 'Currency Conversion Date',
        dataIndex: 'currency_date',
        key: 'currency_date',
      },
      {
        title: 'Currency Rate',
        dataIndex: 'currency_rate',
        key: 'currency_rate',
      },
      {
        title: 'Territory',
        dataIndex: 'territory',
        key: 'territory',
      },
      {
        title: 'Buyer',
        dataIndex: 'buyer',
        key: 'buyer',
      },
      {
        title: 'Account Name',
        dataIndex: 'account_name',
        key: 'account_name',
      },
      {
        title: 'Misc Alpha 1',
        dataIndex: 'misc_alpha_1',
        key: 'misc_alpha_1',
      },
      {
        title: 'Misc Alpha 2',
        dataIndex: 'misc_alpha_2',
        key: 'misc_alpha_2',
      },
      {
        title: 'Misc Alpha 3',
        dataIndex: 'misc_alpha_3',
        key: 'misc_alpha_3',
      },
      {
        title: 'Misc Number 1',
        dataIndex: 'misc_number_1',
        key: 'misc_number_1',
      },
      {
        title: 'Misc Number 2',
        dataIndex: 'misc_number_2',
        key: 'misc_number_2',
      },
      {
        title: 'Invoice Number',
        dataIndex: 'invoice_number',
        key: 'invoice_number',
      },
      {
        title: 'Line Number',
        dataIndex: 'line_number',
        key: 'line_number',
      },
      {
        title: 'File Name',
        dataIndex: 'file_name',
        key: 'file_name',
        filters: fileFilters,
        onFilter: (value, record) => record.file_name.indexOf(value) === 0,
        sorter: (a, b) => a.file_name.localeCompare(b.file_name),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Import Date',
        dataIndex: 'import_date',
        key: 'import_date',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        filters: statusFilters,
        onFilter: (value, record) => record.status.indexOf(value) === 0,
        sorter: (a, b) => a.status.localeCompare(b.status),
        sortDirections: ['descend', 'ascend'],
      },
    ];

    return(
      <Table columns={columns}
        expandIcon={this.expandIcon}
        rowClassName={(record, index) => record.status !== "Success" ? 'failed-row' : '' }
        loading={this.state.loading}
        dataSource={dataSource}
        size='middle'
        scroll={{ x: true }}
        expandedRowRender={record => (
         <span style={{float: "left"}}>{this.parseFailureStatus(record.failure_status)}</span>
        )}
        pagination={{ defaultPageSize: 100, pageSizeOptions: ['10', '25', '50', '100'], showSizeChanger: true }}
      />
    );
  }

  parseFailureStatus(status){
    let parsedStatus = [];

    Object.keys(status).forEach(function(key) {
      parsedStatus.push(status[key]);
    });

    return(
      parsedStatus.map((item) => { return this.renderStatus(item) })
    );
  }

  renderStatus(status){
    return(
      <p>{status}</p>
    )
  }

  alertWindow(message) {
    const { alert, alertClass } = this.state;

    return (
      <Alert bsStyle={"warning"}>
        {message}
      </Alert>
    );
  }

  disabledStartDate(startValue){
    const endValue = this.state.endValue;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  }

  disabledEndDate(endValue){
    const startValue = this.state.startValue;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  }

  onChange(field, value){
    this.setState({
      [field]: value,
    });
  }

  onStartChange(value, dateString){
    this.onChange('startValue', value);
    this.onChange('startDate', dateString);
  }

  onEndChange(value, dateString){
    this.onChange('endValue', value);
    this.onChange('endDate', dateString);
  }

  handleStartOpenChange(open){
    if (!open) {
      this.setState({ endOpen: true });
    }
  }

  handleEndOpenChange(open){
    this.setState({ endOpen: open });
  }

  revenueFileOption(file){
    return <Select.Option key={file.uuid} value={file.uuid}>{file.file_name}</Select.Option>
  }

  setRevenueFile(uuid){
    this.setState({ revenueFileUUID: uuid });
  }

  handleExport(revenueFileUUID, startDate, endDate) {
    const path = "/revenues.csv?revenue_file_uuid=" + revenueFileUUID + "&start_date=" + startDate + "&end_date=" + endDate

    fetch(path)
        .then(response => {
          if (response.ok) {
            const contentDisposition = response.headers.get('Content-Disposition');
            const regex = /filename="([^"]+)"/;
            const match = contentDisposition.match(regex);
            this.filename = match ? match[1] : null;
            return response.blob();
          } else {
            throw Error(response.status);
          }
        })
        .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = this.filename;
          document.body.appendChild(a);
          a.click();
          a.remove();
        })
        .catch(error => {
          if (error.message === "422") {
            alert("The report you requested is currently too large to be processed instantly. For optimal performance, we are preparing it in the background. \nPlease check the 'Sales Exports' Tab later.");
          }
        });
  }

  filterFiles(input, option){
    return (option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
  }

  render(){
    const {revenues, startValue, endValue, startDate, endDate, endOpen, revenueFileUUID, error} = this.state;
    const {revenue_files} = this.props;

    const dateFormat = "MM/DD/YYYY"

    return (
      <div>
        <div className='metaverse-report'>
          <Well>
            <b>From &nbsp;</b>
            <DatePicker
              disabledDate={this.disabledStartDate}
              format={dateFormat}
              value={startValue}
              placeholder="Start"
              onChange={this.onStartChange}
              onOpenChange={this.handleStartOpenChange}
            />
            <b> &nbsp; through &nbsp; </b>
            <DatePicker
              disabledDate={this.disabledEndDate}
              format={dateFormat}
              value={endValue}
              placeholder="End"
              onChange={this.onEndChange}
              open={endOpen}
              onOpenChange={this.handleEndOpenChange}
            />
            &nbsp;&nbsp;&nbsp;&nbsp;<b>File &nbsp;</b>
            <Select defaultValue={revenueFileUUID} style={{ width: 250 }} onChange={this.setRevenueFile} showSearch={true} filterOption={this.filterFiles}>
              <Select.Option value="ALL">All Files</Select.Option>
              {revenue_files.map(this.revenueFileOption)}
            </Select>
            &nbsp;
            <Button icon={<ReadOutlined />} type="primary" onClick={this.retrieveSales}>Preview</Button>
            &nbsp;
            <Button icon={<DownloadOutlined />}
                    onClick={() => { this.handleExport(revenueFileUUID, startDate, endDate) }}
                    >Export
            </Button>
          </Well>
          {revenues.length === 5000 && this.alertWindow('Showing up to 5,000 results. Export to see all entries.')}
          {error && this.alertWindow(error)}
          {this.renderTable()}
        </div>
      </div>
    )
  }
}

export default SalesDetails
