import React from "react";
import { Button, ButtonGroup, Panel, Well, FormControl } from "react-bootstrap";
import InputColumn from "../InputColumn";
import { INVALID_COLUMN_NUMBER, VALUE_NONE } from "../../../constants";
import LookupTable, { REVENUE_TYPE_KEY } from "../../../Utilities/Components/LookupTable";

class SalesMappingStep13 extends React.Component {
  constructor(props) {
    super(props);

    this.setAllowedToMoveOn = this.setAllowedToMoveOn.bind(this);
    this.renderAssignRevenueTypeColumn = this.renderAssignRevenueTypeColumn.bind(this);
    this.renderAssignRevenueTypeColumnUsingLookupTable = this.renderAssignRevenueTypeColumnUsingLookupTable.bind(this);
    this.setColumnValue = this.setColumnValue.bind(this);
    this.setFieldValue = this.setFieldValue.bind(this);

    this.state = {
      allowedToMoveOn: this.initialAllowedToMoveOn(props)
    }
  }

  componentDidMount() {
    const { revenueTypeColumn, lookupColumns } = this.props;
    const { setLookupColumns, setRevenueTypeColumn } = this.props;

    if (revenueTypeColumn && INVALID_COLUMN_NUMBER !== revenueTypeColumn) {
      setRevenueTypeColumn(revenueTypeColumn);
    } else if (lookupColumns && lookupColumns.length > 0) {
      setLookupColumns(lookupColumns);
    }

    if (revenueTypeColumn != null) {
      this.props.setHighlightColumn(parseInt(revenueTypeColumn, 10));
    } else {
      this.props.setHighlightColumn(null);
    }
  }

  initialAllowedToMoveOn(props) {
    const {
      assignRevenueTypeColumn, revenueTypeColumn,
      assignRevenueTypeField, revenueTypeField,
      assignRevenueTypeColumnUsingLookupTable, lookupColumns

    } = props;

    if (assignRevenueTypeColumn) {
      return INVALID_COLUMN_NUMBER !== revenueTypeColumn;
    }

    if (assignRevenueTypeField) {
      return "" !== revenueTypeField;
    }

    if (assignRevenueTypeColumnUsingLookupTable) {
      return lookupColumns && lookupColumns.length > 0;
    }

    return true;
  }

  setAllowedToMoveOn(value) {
    this.setState({ allowedToMoveOn: value });
  }

  addUpdateButton() {
    if (this.props.editingMapping) {
      return (
        <Button
          key="update"
          bsStyle="primary"
          bsSize="small"
          onClick={this.props.assignNamePage}
        >
          Finish Editing and Update
        </Button>
      );
    }
  }

  setColumnValue(event) {
    if (VALUE_NONE === event.target.value) {
      this.props.setRevenueTypeColumn(event.target.value);
    } else {
      this.props.setRevenueTypeColumn(parseInt(event.target.value, 10));
    }

    if (event.target.value) {
      this.setState({ allowedToMoveOn: true });
    }
  }

  setFieldValue(event) {
    const {setRevenueTypeField} = this.props;
    const revenueTypeField = event.target.value;
    let allowedToMoveOn;

    allowedToMoveOn = revenueTypeField != null && revenueTypeField !== "";

    this.setState({allowedToMoveOn}, () => {
        setRevenueTypeField(revenueTypeField);
    });
  }

  renderAssignRevenueTypeColumn() {
    if (this.props.assignRevenueTypeColumn) {
      return (
        <Well>
          <p>Which column contains the 'Revenue Type'?</p>

          <InputColumn
            columnNames={this.props.columnNames}
            value={this.props.revenueTypeColumn}
            setColumn={this.setColumnValue}
            addNoneSelection={false}
          />

          <p>
            Upon picking a column, it will appear highlighted below. Please
            confirm your selection and click Next.
          </p>
        </Well>
      );
    } else {
      return <div></div>;
    }
  }

  renderAssignRevenueTypeColumnUsingLookupTable() {
    const { lookupProcessingEnabled, assignRevenueTypeColumnUsingLookupTable } = this.props;

    if (lookupProcessingEnabled && assignRevenueTypeColumnUsingLookupTable) {
      const { columnNames, lookupColumns, setLookupColumns } = this.props;

      return (
        <Well>
          <p>Which column(s) in your submitted file should the system use to find the 'Revenue Type'?</p>

          <LookupTable
            header={REVENUE_TYPE_KEY}
            columnNames={columnNames}
            lookupColumns={lookupColumns}
            setLookupColumns={setLookupColumns}
            setAllowedToMoveOn={this.setAllowedToMoveOn}
          />

          <p className="ant-form-extra">
            Please confirm your selection and click Next.
          </p>
        </Well>
      );
    } else {
      return <div></div>;
    }
  }

  renderAssignRevenueType() {
    if (this.props.assignRevenueTypeField) {
      return (
        <Well>
          <p>What Revenue Type do you want to assign?</p>

          <FormControl
            key="revenueTypeField"
            type="text"
            onChange={this.setFieldValue}
            label="Revenue Type"
            defaultValue={this.props.revenueTypeField}
          />

          <p>Please confirm your selection and click Next.</p>
        </Well>
      );
    } else {
      return <div></div>;
    }
  }

  confirm(callback) {
    const {
      revenueTypeField,
      revenueTypeColumn,
      skipRevenueType,
      setAssignRevenueTypeField,
      setAssignRevenueTypeColumnUsingLookupTable,
      setAssignRevenueTypeColumn,
      assignRevenueTypeColumn,
      assignRevenueTypeColumnUsingLookupTable,
      assignRevenueTypeField
    } = this.props;

    let allowedToMoveOn = false;

    if (callback === setAssignRevenueTypeField) {
      allowedToMoveOn = revenueTypeField != null && revenueTypeField !== "";
    } else if (callback === skipRevenueType) {
      allowedToMoveOn = true;
    } else if (callback === setAssignRevenueTypeColumn) {
      allowedToMoveOn = revenueTypeColumn != null && revenueTypeColumn !== "" && INVALID_COLUMN_NUMBER !== revenueTypeColumn;
    }

    if (!assignRevenueTypeColumn && !assignRevenueTypeColumnUsingLookupTable && !assignRevenueTypeField) {
      this.setState({ allowedToMoveOn }, callback);
    } else if (
      (assignRevenueTypeColumn || assignRevenueTypeColumnUsingLookupTable || assignRevenueTypeField) &&
      window.confirm('Are you sure you want to change this setting? Any unsaved changes will be lost.')
    ) {
      this.setState({ allowedToMoveOn: allowedToMoveOn }, callback);
    }
  }

  render() {
    const { allowedToMoveOn } = this.state;
    const {
      setAssignRevenueTypeColumn, setAssignRevenueTypeColumnUsingLookupTable, setAssignRevenueTypeField, skipRevenueType,
      assignRevenueTypeColumn, assignRevenueTypeColumnUsingLookupTable, assignRevenueTypeField, lookupProcessingEnabled
    } = this.props;

    return (
      <Panel
        header={
          <div>
            <div>
              Revenue Types
              <div className="pull-right">{this.addUpdateButton()}</div>
            </div>
          </div>
        }
      >
        <div>
          <p>
            Sales Aggregator can apply a revenue type to each sale in the file,
            assign the revenue type found in the file, or leave the revenue type
            column blank.
          </p>

          <ButtonGroup style={{ marginBottom: "1em"  }}>
            <Button
              key="revenueTypeFromColumn"
              bsSize="small"
              onClick={() => this.confirm(setAssignRevenueTypeColumn)}
              bsStyle={assignRevenueTypeColumn === true ? "primary" : "default"}
            >
              Assign Revenue Type Based on Column
            </Button>
            { lookupProcessingEnabled &&
              <Button
                key="revenueTypeFromColumnUsingLookupTable"
                bsSize="small"
                onClick={() => this.confirm(setAssignRevenueTypeColumnUsingLookupTable)}
                bsStyle={assignRevenueTypeColumnUsingLookupTable === true ? "primary" : "default"}
              >
                Assign Revenue Type Based on Lookup Table
              </Button>
            }
            <Button
              key="revenueTypeFromWholeFile"
              bsSize="small"
              onClick={() => this.confirm(setAssignRevenueTypeField)}
              bsStyle={assignRevenueTypeField === true ? "primary" : "default"}
            >
              Assign Revenue Type for All Entries in File
            </Button>
            <Button
              key="revenueTypeSkip"
              bsSize="small"
              onClick={() => this.confirm(skipRevenueType)}
              bsStyle={(
                !assignRevenueTypeField &&
                  !assignRevenueTypeColumn &&
                  !assignRevenueTypeColumnUsingLookupTable &&
                  allowedToMoveOn ? "primary" : "default"
              )}
            >
              Do Not Assign A Revenue Type
            </Button>
          </ButtonGroup>
        </div>

        {this.renderAssignRevenueTypeColumn()}
        {this.renderAssignRevenueTypeColumnUsingLookupTable()}
        {this.renderAssignRevenueType()}

        <div className="pull-left">
          <Button
            key="previousPage"
            bsStyle="primary"
            className="pull-right"
            onClick={this.props.previousPage}
          >
            Back
          </Button>
        </div>
        <div className="pull-right">
          <Button
            key="nextPage"
            bsStyle="primary"
            disabled={!allowedToMoveOn}
            onClick={this.props.nextPage}
          >
            Next
          </Button>
        </div>
      </Panel>
    );
  }
}

export default SalesMappingStep13;
