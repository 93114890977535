import React from 'react'
import { Table } from 'antd';
import $ from "jquery";
import moment from 'moment';

const LIMIT = 500;

class SalesExportList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sales_exports: [],
      loading: false,
    };
    this.retrieveSalesExports = this.retrieveSalesExports.bind(this);
  }

  componentWillMount(){
    this.retrieveSalesExports();
  }

  retrieveSalesExports(){
    const { sales_exports }= this.props;

    this.setState({
      loading: true
    });

    $.ajax({
      url: `/sales_exports.json?limit=${LIMIT}`,
      type: "get",
      statusCode: {
        200: (response) => {
          this.setState({
            sales_exports: response,
            error: null,
            loading: false
          });
        }
      }
    }).fail((response) => {
      switch (response.status) {
        case 422:
          const error = response.responseJSON.error;
          this.setState({
            loading: false,
            error: error
          });
          break;
        default:
          this.setState({
            loading: false,
            error: "Something went wrong. Please try again later."
          });
          break;
      }
    });
  }

  durationInfo(sales_export) {
    console.log(sales_export);
    if (sales_export.finished_at == null || sales_export.started_at == null) {
      return '...';
    }

    const startedAt = moment(sales_export.started_at);
    const finishedAt = moment(sales_export.finished_at);

    return finishedAt.from(startedAt, true);

  }

  pushRow(rows, sales_export){
    rows.push({key: sales_export.uuid,
      started_at: sales_export.started_at,
      finished_at: sales_export.finished_at,
      file_name: sales_export.file_name,
      url: sales_export.url,
      params: sales_export.humanized_params
    });
  }

  renderTable() {
    const { sales_exports } = this.state;

    let dataSource = [];
    sales_exports.map(sales_export => this.pushRow(dataSource, sales_export));

    const columns = [
      {
        title: 'Generated At',
        dataIndex: 'finished_at',
        render: (text, record) => {
          if (record.finished_at === null) {
              return (<span>...</span>);
          }

          return (<span title={this.durationInfo(record)}>{moment(record.finished_at).format('LLL')}</span>);
        },
        key: 'finished_at',
        width: '10%',
        align: 'left'
      },
      {
        title: 'File',
        dataIndex: 'file_name',
        render: (text, record) => {
          if (record.url === null) {
            return (<span>Processing...</span>);
          }

          return (<a href={record.url}>{text}</a>);
        },
        key: 'file_name',
        width: '40%',
        align: 'left'
      },
      {
        title: 'Filters Used',
        dataIndex: 'params',
        key: 'params',
        align: 'left'
      }
    ];

    return(
      <Table columns={columns}
        loading={this.state.loading}
        dataSource={dataSource}
        size='small'
        scroll={{ x: true }}
        pagination={{ defaultPageSize: 100, pageSizeOptions: ['10', '25', '50', '100'], showSizeChanger: true }}
      />
    );
  }

  render(){
    const {sales_exports, error} = this.state;

    return (
      <div>
        <div className='metaverse-report'>
          {this.renderTable()}
        </div>
      </div>
    )
  }
}

export default SalesExportList
