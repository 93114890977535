import React from "react";
import { Button, Panel, Well } from "react-bootstrap";
import SalesMappingStep3a from "./SalesMappingStep3a";
import { INVALID_COLUMN_NUMBER } from "../../../constants";

class SalesMappingStep3 extends React.Component {
  constructor(props) {
    super(props);

    this.setAssignDateTrue = this.setAssignDateTrue.bind(this);
    this.setAssignDateFalse = this.setAssignDateFalse.bind(this);
    this.setDateFormat = this.setDateFormat.bind(this);
    this.setTransactionDateColumn = this.setTransactionDateColumn.bind(this);
    this.allowedToMoveOn = this.allowedToMoveOn.bind(this);
  }

  componentDidMount() {
    const { transactionDateColumn } = this.props;

    if (transactionDateColumn != null) {
      this.props.setHighlightColumn(parseInt(transactionDateColumn, 10));
    } else {
      this.props.setHighlightColumn(null);
    }
  }

  setTransactionDateColumn(event) {
    this.props.setColumn(
      "transactionDateColumn",
      parseInt(event.target.value, 10)
    );
  }

  setDateFormat(event) {
    this.props.setDateFormat(event.target.value);
  }

  setAssignDateTrue() {
    this.props.setAssignDate(true);
  }

  setAssignDateFalse() {
    this.props.setAssignDate(false);
  }

  allowedToMoveOn() {
    const assignDate = this.props.assignDate === true;
    const dateFromFileParametersSet =
      false == this.props.assignDate &&
      INVALID_COLUMN_NUMBER !== this.props.transactionDateColumn;

    return assignDate || dateFromFileParametersSet;
  }

  addUpdateButton() {
    if (this.props.editingMapping) {
      return (
        <Button
          key="update"
          bsStyle="primary"
          bsSize="small"
          onClick={this.props.assignNamePage}
        >
          Finish Editing and Update
        </Button>
      );
    }
  }

  render() {
    const { assignDate } = this.props;

    return (
      <Panel
        header={
          <div>
            <div>
              Transaction Date
              <div className="pull-right">{this.addUpdateButton()}</div>
            </div>
          </div>
        }
      >
        <p>
          We will now determine how to assign dates to this file. If your file
          contains a date column that you would like to automatically apply to
          each transaction, please select ‘Use Date Column’ below. Otherwise, if
          there is no date column or if you would like to manually assign a date
          when the file is imported, select ‘Assign Date on Import’.
        </p>
        <Well>
          Does your sales file contain a date column?
          <br /> &nbsp;
          <div>
            <Button
              key="assignDateNo"
              bsSize="small"
              onClick={this.setAssignDateFalse}
              bsStyle={assignDate === false ? "primary" : "default"}
            >
              Use Date Column
            </Button>{" "}
            &nbsp;
            <Button
              key="assignDateYes"
              bsSize="small"
              onClick={this.setAssignDateTrue}
              bsStyle={assignDate === true ? "primary" : "default"}
            >
              Assign Date on Import
            </Button>
          </div>
        </Well>

        <div>
          <SalesMappingStep3a
            columnNames={this.props.columnNames}
            assignDate={this.props.assignDate}
            dateColumn={this.props.transactionDateColumn}
            setTransactionDateColumn={this.setTransactionDateColumn}
            dateFormat={this.props.dateFormat}
            setDateFormat={this.setDateFormat}
          />
        </div>

        <div className="pull-left">
          <Button
            key="previousPage"
            bsStyle="primary"
            onClick={this.props.previousPage}
          >
            Back
          </Button>
        </div>
        <div className="pull-right">
          <Button
            key="nextPage"
            bsStyle="primary"
            disabled={!this.allowedToMoveOn()}
            onClick={this.props.nextPage}
          >
            Next
          </Button>
        </div>
      </Panel>
    );
  }
}

export default SalesMappingStep3;
