import React from "react";
import { Button, Panel, FormControl } from "react-bootstrap";

class SalesMappingStep2b extends React.Component {
  constructor(props) {
    super(props);

    this.handleTextChanged = this.handleTextChanged.bind(this);

    this.state = {
      selectedRow: this.props.headerRow + 1,
    };
  }

  handleTextChanged(event) {
    const { setHeaderRow } = this.props;
    const selectedRow = event.target.value;
    this.setState({ selectedRow });

    const rowNumber = parseInt(selectedRow, 10);
    if (!isNaN(rowNumber)) {
      setHeaderRow(rowNumber);
    }
  }

  render() {
    return (
      <Panel header={<div>Header Row</div>}>
        <div>
          Below is the first few lines of your file. We expect the first line to
          be the header row. However, some files will have some irrelevant data
          for the first few rows. If the first row is not the header row, please
          tell us what line it begins on. <br /> &nbsp;
          <FormControl
            type="number"
            value={this.state.selectedRow}
            step={1}
            min={1}
            max={20}
            label="Row Number"
            onChange={this.handleTextChanged}
          />
        </div>
        <div className="pull-left">
          <Button
            key="previousPage"
            bsStyle="primary"
            onClick={this.props.previousPage}
          >
            Back
          </Button>
        </div>
        <div className="pull-right">
          <Button
            key="nextPage"
            bsStyle="primary"
            onClick={this.props.nextPage}
          >
            Next
          </Button>
        </div>
      </Panel>
    );
  }
}

export default SalesMappingStep2b;
