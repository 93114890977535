import React from "react";
class SalesMappingStep11e extends React.Component {
  render() {
    const { specifyCurrencyDate } = this.props;

    if (specifyCurrencyDate === true) {
      return (
        <div>
          OK, we'll allow you to specify a date for the currency when we import
          the file.
          <br /> &nbsp;
        </div>
      );
    } else {
      if (specifyCurrencyDate === false) {
        return (
          <div>
            OK, we'll use the transaction date. <br /> &nbsp;
          </div>
        );
      } else {
        return <div></div>;
      }
    }
  }
}

export default SalesMappingStep11e;
