import React from "react";
import { Button, Panel } from "react-bootstrap";
import InputColumn from "../InputColumn";
import { VALUE_NONE } from "../../../constants";

class SalesMappingStep extends React.Component {
  constructor(props) {
    super(props);

    this.setColumnValue = this.setColumnValue.bind(this);
    this.skipSelection = this.skipSelection.bind(this);
  }

  componentDidMount() {
    const { mappingColumn } = this.props;

    if (mappingColumn != null) {
      this.props.setHighlightColumn(parseInt(mappingColumn, 10));
    } else {
      this.props.setHighlightColumn(null);
    }
  }

  setColumnValue(event) {
    if (VALUE_NONE === event.target.value) {
      this.props.setColumn(this.props.columnName, event.target.value);
    } else {
      this.props.setColumn(
        this.props.columnName,
        parseInt(event.target.value, 10)
      );
    }
  }

  skipSelection() {
    this.props.setColumn(this.props.columnName, VALUE_NONE);
    this.props.nextPage();
  }

  addUpdateButton() {
    if (this.props.editingMapping) {
      return (
        <Button
          key="update"
          bsStyle="primary"
          bsSize="small"
          onClick={this.props.assignNamePage}
        >
          Finish Editing and Update
        </Button>
      );
    }
  }

  addSkipButton() {
    if (this.props.addNoneSelection) {
      return (
        <Button key="skip" bsStyle="warning" onClick={this.skipSelection}>
          Skip
        </Button>
      );
    }
  }

  render() {
    return (
      <Panel
        header={
          <div>
            <div>
              {this.props.panelTitle}
              <div className="pull-right">{this.addUpdateButton()}</div>
            </div>
          </div>
        }
      >
        <div>
          <p>{this.props.description}</p>

          <InputColumn
            columnNames={this.props.columnNames}
            value={this.props.mappingColumn}
            setColumn={this.setColumnValue}
            addNoneSelection={this.props.addNoneSelection}
          />

          <p>
            Upon picking a column, it will appear highlighted below. Please
            confirm your selection and click Next.
          </p>
        </div>

        <div className="pull-left">
          <Button
            key="previousPage"
            bsStyle="primary"
            className="pull-right"
            onClick={this.props.previousPage}
          >
            Back
          </Button>
        </div>
        <div className="pull-right">
          {this.addSkipButton()}
          <Button
            key="nextPage"
            bsStyle="primary"
            onClick={this.props.nextPage}
            disabled={this.props.disableNext}
          >
            Next
          </Button>
        </div>
      </Panel>
    );
  }
}

export default SalesMappingStep;
