import React from 'react';
import { OverlayTrigger, Popover, FormControl } from 'react-bootstrap';
import DayPicker from 'react-day-picker';
import classNames from "classnames";
import parseDateOrNull from "../parseDateOrNull"
import defaultDateFormatter from "../defaultDateFormatter"

class DateChooser extends React.Component {
  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleTextChanged = this.handleTextChanged.bind(this);
    this.handleTextBlur = this.handleTextBlur.bind(this);
    this.state = this.initialState(props);
  }

  componentWillReceiveProps(props) {
    this.setState(this.initialState(props));
  }

  initialState( { value, dateFormatter } ) {
    return { dateString: dateFormatter(value), dateFormat: 'mm/dd/yyyy' };
  }

  handleDayClick(event, date) {
    const { onChange } = this.props;
    onChange(date);
    this.refs.elemOverlayTrigger.hide();
  }

  handleTextChanged(event) {
    const dateString = event.target.value; 
    this.setState( { dateString } );
  }

  handleTextBlur() {
    const { onChange } = this.props;
    const { dateString } = this.state;
    onChange(parseDateOrNull(dateString));
  }

  datePicker() {
    return (<DayPicker
      numberOfMonths={1}
      enableOutsideDays={true}
      onDayClick={this.handleDayClick}
    />);
  }

  formGroupClassNames() {
    const { dateString } = this.state;
    return classNames( "form-group", {
      "has-error": ( dateString && parseDateOrNull(dateString) == null )
    });
  }

  render() {
    const { placement, disabled } = this.props;
    const { dateString, dateFormat } = this.state;

    return (
      <div style={{position: "relative"}}>
        <OverlayTrigger
          placement={placement}
          trigger='click'
          rootClose
          container={this}
          ref="elemOverlayTrigger"
          overlay={<Popover id="datepicker">{this.datePicker()}</Popover>}>
          <div className={this.formGroupClassNames()}>
            <FormControl
              placeholder={dateFormat}
              disabled={disabled}
              type="text"
              value={dateString}
              onChange={this.handleTextChanged}
              onBlur={this.handleTextBlur}
            />
          </div>
        </OverlayTrigger>
      </div>
    );
  }
}

DateChooser.defaultProps = {
  dateFormatter: defaultDateFormatter,
  placement: 'bottom'
};

export default DateChooser;
