import React from "react";
import { Button, Panel } from "react-bootstrap";
import SalesMappingStep12a from "./SalesMappingStep12a";
import SalesMappingStep12b from "./SalesMappingStep12b";
import {
  VALUE_NONE,
  DEDUCTION_TYPE_NO,
  DEDUCTION_TYPE_FILE,
  DEDUCTION_TYPE_WHOLE,
} from "../../../constants";

class SalesMappingStep12 extends React.Component {
  constructor(props) {
    super(props);

    this.setDeductionInFile = this.setDeductionInFile.bind(this);
    this.setDeductionWholeFile = this.setDeductionWholeFile.bind(this);
    this.setNoDeduction = this.setNoDeduction.bind(this);
    this.setSalesDeductionEvent = this.setSalesDeductionEvent.bind(this);
    this.setDeductionColumn = this.setDeductionColumn.bind(this);
    this.allowedToMoveOn = this.allowedToMoveOn.bind(this);
    this.negativeDeductionSignPositive =
      this.negativeDeductionSignPositive.bind(this);
    this.negativeDeductionSignNegative =
      this.negativeDeductionSignNegative.bind(this);
  }

  componentDidMount() {
    const { deductionColumn } = this.props;

    if (deductionColumn != null) {
      this.props.setHighlightColumn(parseInt(deductionColumn, 10));
    } else {
      this.props.setHighlightColumn(null);
    }
  }

  allowedToMoveOn() {
    return (
      this.props.deductionType === DEDUCTION_TYPE_NO ||
      (this.props.deductionType === DEDUCTION_TYPE_FILE &&
        this.props.deductionColumn !== VALUE_NONE &&
        this.props.negativeDeductionSign !== null) ||
      (this.props.deductionType === DEDUCTION_TYPE_WHOLE &&
        this.props.salesDeductionRate &&
        this.props.salesDeductionRate != 0 &&
        this.props.salesDeductionRate != NaN)
    );
  }

  setDeductionInFile() {
    this.props.setDeductionType(DEDUCTION_TYPE_FILE);
    this.props.setSalesDeductionRate(0);
  }

  setDeductionWholeFile() {
    this.props.setDeductionType(DEDUCTION_TYPE_WHOLE);
    this.props.setColumn("deductionColumn", VALUE_NONE);
    this.props.setColumn("negativeDeductionSign", null);
  }

  setNoDeduction() {
    this.props.setDeductionType(DEDUCTION_TYPE_NO);
    this.props.setSalesDeductionRate(0);
    this.props.setColumn("deductionColumn", VALUE_NONE);
    this.props.setColumn("negativeDeductionSign", null);
  }

  negativeDeductionSignPositive() {
    this.props.setColumn("negativeDeductionSign", false);
  }

  negativeDeductionSignNegative() {
    this.props.setColumn("negativeDeductionSign", true);
  }

  setSalesDeductionEvent(event) {
    this.props.setSalesDeductionRate(parseFloat(event.target.value, 10));
  }

  setDeductionColumn(event) {
    this.props.setColumn("deductionColumn", parseInt(event.target.value, 10));
  }

  addUpdateButton() {
    if (this.props.editingMapping) {
      return (
        <Button
          key="update"
          bsStyle="primary"
          bsSize="small"
          onClick={this.props.assignNamePage}
        >
          Finish Editing and Update
        </Button>
      );
    }
  }

  determineDeductionType(obj) {
    let determinedValue = obj.deductionType;
    const { deductionColumn, negativeDeductionSign, salesDeductionRate } = obj;

    /* Note that if the obj (props) value is null, that means that we're working on a NEW template,
      so we can leave it as null. But if we're editing an EXISTING template, then the obj value
      will be undefined, because this is not a value that is ever saved to the DB. */
    if (undefined === determinedValue) {
      if (deductionColumn && null !== negativeDeductionSign) {
        determinedValue = DEDUCTION_TYPE_FILE;
      } else if (salesDeductionRate && salesDeductionRate != 0 && salesDeductionRate != NaN) {
        determinedValue = DEDUCTION_TYPE_WHOLE;
      } else {
        determinedValue = DEDUCTION_TYPE_NO;
      }
    }

    return determinedValue;
  }

  render() {
    const deductionType = this.determineDeductionType(this.props);

    return (
      <Panel
        header={
          <div>
            <div>
              Deductions
              <div className="pull-right">{this.addUpdateButton()}</div>
            </div>
          </div>
        }
      >
        <div>
          <p>
            Sales Aggregator can apply deductions to the net received. This is
            useful if you need to deduct certain fees prior to paying royalties.
            If you apply deductions, we will include both the original net
            received and the net amount after deductions as two separate
            columns.
          </p>
          <Button
            key="deductionWhole"
            bsSize="small"
            onClick={this.setDeductionWholeFile}
            bsStyle={deductionType === "WHOLE" ? "primary" : "default"}
          >
            Deduct a fixed percentage from all line items
          </Button>
          &nbsp;
          <Button
            key="deductionFile"
            bsSize="small"
            onClick={this.setDeductionInFile}
            bsStyle={deductionType === "FILE" ? "primary" : "default"}
          >
            Deduct amount specified in a 'Deduction' column
          </Button>
          &nbsp;
          <Button
            key="deductionNo"
            bsSize="small"
            onClick={this.setNoDeduction}
            bsStyle={deductionType === "NO" ? "primary" : "default"}
          >
            No deductions
          </Button>
          <br /> &nbsp;
          <SalesMappingStep12a
            deductionType={this.props.deductionType}
            setSalesDeductionEvent={this.setSalesDeductionEvent}
            salesDeductionRate={this.props.salesDeductionRate}
          />
          <SalesMappingStep12b
            columnNames={this.props.columnNames}
            deductionType={this.props.deductionType}
            deductionColumn={this.props.deductionColumn}
            negativeDeductionSignPositive={this.negativeDeductionSignPositive}
            negativeDeductionSignNegative={this.negativeDeductionSignNegative}
            setColumn={this.setDeductionColumn}
          />
        </div>

        <div className="pull-left">
          <Button
            key="previousPage"
            bsStyle="primary"
            className="pull-right"
            onClick={this.props.previousPage}
          >
            Back
          </Button>
        </div>
        <div className="pull-right">
          <Button
            key="nextPage"
            bsStyle="primary"
            disabled={!this.allowedToMoveOn()}
            onClick={this.props.nextPage}
          >
            Next
          </Button>
        </div>
      </Panel>
    );
  }
}

export default SalesMappingStep12;
