import React from "react";
import { Button, Panel, FormControl } from "react-bootstrap";

class SalesMappingStepAssignName extends React.Component {
  constructor(props) {
    super(props);

    this.changeName = this.changeName.bind(this);
    this.changeNotes = this.changeNotes.bind(this);
  }

  componentDidMount() {
    this.props.setHighlightColumn(null);
  }

  changeNotes(event) {
    this.props.setNotes(event.target.value);
  }

  changeName(event) {
    this.props.setName(event.target.value);
  }

  addUpdateButton() {
    if (this.props.editingMapping) {
      return (
        <Button
          key="update"
          bsStyle="primary"
          onClick={this.props.updateMapping}
        >
          Update
        </Button>
      );
    }
  }

  render() {
    return (
      <Panel header={<div>Save the Template</div>}>
        <div>
          Finally, we need to name this template so you can identify it for
          later use.
          <FormControl
            key="name"
            type="text"
            onChange={this.changeName}
            label="File Mapping Name"
            value={this.props.name}
          />
          <br />
          Also, you may enter in any notes that might help you when you import
          files.
          <FormControl
            key="notes"
            componentClass="textarea"
            rows={2}
            onChange={this.changeNotes}
            label="Notes"
            value={this.props.notes}
          />
          <br />
          <p>Once this is done, click Next.</p>
        </div>

        <div className="pull-left">
          <Button
            key="previousPage"
            bsStyle="primary"
            className="pull-right"
            onClick={this.props.previousPage}
          >
            Back
          </Button>
        </div>
        <div className="pull-right">
          <Button
            key="nextPage"
            bsStyle="primary"
            disabled={!this.props.name || this.props.name.trim() === ""}
            onClick={this.props.nextPage}
          >
            Next
          </Button>
        </div>
      </Panel>
    );
  }
}

export default SalesMappingStepAssignName;
