import React from 'react'
import { Alert, Input, Spin } from 'antd';
import { Panel, Button } from "react-bootstrap";
import { DragDrop, ProgressBar } from "@uppy/react";
import AwsS3 from '@uppy/aws-s3';
import Uppy from '@uppy/core';
import { CURRENCIES } from '../../../constants';

class ImportSalesStep2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      originalFileName: '',
      uploadedFileData: null,
      fileUploaded: false,
      conversionRates: undefined,
    };

    this.uppy = new Uppy({
      id: 'revenueFile',
      autoProceed: true,
      allowMultipleUploads: false,
      restrictions: { maxNumberOfFiles: 1, allowedFileTypes: ['.xls', '.csv', '.xlsx', '.ods', '.txt', '.tsv'] },
    }).use(AwsS3, {
      companionUrl: '/',
    }).on('upload-success', (file) => {
      const { activeMapping } = props;
      const defineConversionRates = activeMapping.currency_in_file === true && activeMapping.currency_code_column;
      const uploadedFileData = {
        id: file.meta['key'].match(/^cache\/(.+)/)[1],
        storage: 'cache',
        metadata: {
          size: file.size,
          filename: file.name,
          mime_type: file.type,
        }
      };

      this.setState({
        originalFileName: file.name,
        uploadedFileData: uploadedFileData,
        fileUploaded: true,
      });

      if (defineConversionRates) {
        this.props.loadCurrencies(file.name, uploadedFileData)
          .catch((error) => {
            this.setState({ conversionRates: [] });
          })
          .then((currencies) => {
            const conversionRates = currencies.reduce((acc, cur) => {
              acc[cur] = null;
              return acc;
            }, {});

            this.setState({ conversionRates });
          });
      }

    });
  }

  componentWillUnmount() {
    this.uppy.close();
  }

  renderConversionRates(conversionRates) {
    if (typeof conversionRates === 'undefined') {
      return <div><Spin style={{ marginRight: 10 }} />Loading currencies from file...</div>;
    } else if (Object.keys(conversionRates).length === 0) {
      return <Alert message="The uploaded file does not have currency codes in the column specified by the template." type="warning" showIcon />
    }

    function setConversionRate(rate, value) {
      const { conversionRates } = this.state;

      conversionRates[rate] = value;
      this.setState({ conversionRates: conversionRates });
    }

    return (
      <table>
        <thead className="ant-table-thead">
          <tr>
            <th>Original Currency</th>
            <th>Manual Rate</th>
            <th>Online Rate</th>
          </tr>
        </thead>
        <tbody className="ant-table-tbody">
          {Object.keys(conversionRates).map((rate) => (
            <tr key={rate}>
              <td><label htmlFor={`rate[${rate}]`}>{CURRENCIES[rate] ? `${rate} (${CURRENCIES[rate]})` : rate}</label></td>
              <td>
                <Input
                  type="text"
                  id={`rate[${rate}]`}
                  style={{ width: 'auto' }}
                  disabled={CURRENCIES[rate] && conversionRates[rate] === null}
                  onChange={(e) => setConversionRate.call(this, rate, e.target.value)}
                  onBlur={(e) => CURRENCIES[rate] && e.target.value === '' && setConversionRate.call(this, rate, null)}
                  value={conversionRates[rate] || ''}
                  pattern="[0-9]*[.]?[0-9]+"
                />
              </td>
              {CURRENCIES[rate] ? (
                <td>
                  <input
                    type="checkbox"
                    checked={conversionRates[rate] === null}
                    onChange={(e) => setConversionRate.call(this, rate, e.target.checked ? null : 1)}
                  /> Lookup conversion rate
                </td>) : <td>Not available</td>}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  render() {
    const { activeMapping, assignedCurrencyDate } = this.props;
    const { fileUploaded, originalFileName, uploadedFileData, conversionRates } = this.state;
    const defineConversionRates = activeMapping.currency_in_file === true &&
                                  activeMapping.conversion_rate !== true &&
                                  activeMapping.currency_code_column;

    return (
      <div>
        <Panel header={<div>Import Sales</div>}>
          What is the sales file that you want to import? <br /> &nbsp;

          {!fileUploaded && <DragDrop uppy={this.uppy} />}
          <ProgressBar uppy={this.uppy} hideAfterFinish={false} />

          {uploadedFileData && defineConversionRates && (
            <div style={{ marginTop: 20 }}>
              <p>This template uses:</p>
              <ul>
                <li>The value of 'Column #{activeMapping.currency_code_column + 1}' to identify the currency code.</li>
                {activeMapping.target_currency_code && <li>The target currency conversion '{activeMapping.target_currency_code}'. Any rows at that currency will stay the same and not be converted.</li>}
                <li>{activeMapping.specify_currency_date ? `'${assignedCurrencyDate.format('MM/DD/YYYY')}' for the date of the currency conversion` : 'The transaction date for the date of the currency conversion'}</li>
              </ul>

              <p>Define the conversion rate for each currency, or let the system look up the rate for you.</p>

              {this.renderConversionRates(conversionRates)}
            </div>
          )}
        </Panel>

        <div className="pull-left">
          <Button key='lastPage' bsStyle="primary" onClick={this.props.setPageToStart}>Back</Button>
        </div>

        <div className="pull-right">
          <Button key='importFileBtn' bsStyle="primary" disabled={!fileUploaded || (defineConversionRates && !conversionRates)} onClick={() =>
            this.props.sendFile(originalFileName, uploadedFileData, conversionRates)}>
            Import Sales File
          </Button>
        </div>

      </div>
    )
  }
}

export default ImportSalesStep2
