import React from "react";
import { Well, Button } from "react-bootstrap";
class SalesMappingStep11d extends React.Component {
  render() {
    const {
      currencyConversion,
      specifyCurrencyDate,
      conversionRate,
    } = this.props;

    if (currencyConversion === true && !conversionRate) {
      return (
        <Well>
          Sales Aggregator will look up which currency exchange rate to use based on a date.  Would you like to specify the date when you import the file, or use the transaction date in the file?
          <br />
          &nbsp;
          <div>
            <Button
              key="assignCurrencyDateFalse"
              bsSize="small"
              bsStyle={specifyCurrencyDate === false ? "primary" : "default"}
              onClick={this.props.specifyCurrencyDateFalse}
            >
              Use the <b>transaction date</b> for the
              <br />
              sales lines for conversion
            </Button>
            &nbsp;
            <Button
              key="assignCurrencyDateTrue"
              bsSize="small"
              bsStyle={specifyCurrencyDate === true ? "primary" : "default"}
              onClick={this.props.specifyCurrencyDateTrue}
            >
              I want to specify a{" "}
              <b>
                conversion date
              </b>
              <br />
              for the whole file on import process.
            </Button>
          </div>
        </Well>
      );
    } else {
      if (currencyConversion === false) {
        return <div></div>;
      } else {
        return <div></div>;
      }
    }
  }
}

export default SalesMappingStep11d;
