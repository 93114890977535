import React from 'react';
import ReactDOM from 'react-dom';
import { Modal, Button } from 'react-bootstrap';

class ConfirmModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = { opened: true };
        this.close = this.close.bind(this);
        this.confirm = this.confirm.bind(this);
    }

    confirm(event) {
        event.preventDefault();
        const { onConfirm } = this.props;
        this.setState({ opened: false });
        onConfirm();
    }

    close() {
        this.setState({ opened: false });
    }

    render() {
        const { opened } = this.state;
        const { header, content,
            yesLabel, noLabel,
            yesStyle, noStyle,
            } = this.props;

        return (
            <Modal show={opened} onHide={this.close}>
                <Modal.Header closeButton>
                    <Modal.Title>{header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {content}
                </Modal.Body>
                <Modal.Footer>
                    <Button key="yes"
                            bsStyle={yesStyle}
                            onClick={this.confirm}>{yesLabel}</Button>
                    <Button key="no"
                            bsStyle={noStyle}
                            onClick={this.close}>{noLabel}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

ConfirmModal.defaultProps = {
    yesLabel: 'Yes',
    yesStyle: 'primary',
    noLabel: 'No',
    noStyle: undefined
}

function findConfirmModalDOM() {
    let confirmModalDOM = document.getElementById("confirm-modal-container");

    if(confirmModalDOM === null) {
        confirmModalDOM = document.createElement("div");
        confirmModalDOM.id = "confirm-modal-container";
        document.body.appendChild(confirmModalDOM);
    }

    return confirmModalDOM;
}

function Confirm(content, options = {}) {
    const confirmModalDOM = findConfirmModalDOM();

    ReactDOM.unmountComponentAtNode(confirmModalDOM);
    Object.assign(options, { content });

    const confirmPromise = new Promise((resolve, reject) => {
        ReactDOM.render(<ConfirmModal {...options}
                                      onClose={reject}
                                      onConfirm={resolve} />, confirmModalDOM);
    });

    return confirmPromise;
}

export default Confirm;