import React from "react"
import { Navbar, Nav, NavItem, Grid, Row } from "react-bootstrap";
import CompanyChooser from "./Utilities/Components/CompanyChooser";

class Navigation extends React.Component {
  detailsAndLogout() {
    const { current_user } = this.props;
    const { username } = current_user;

    return (
      <div key="user-details-logout-link">
        <span className="user-name">
          Hello {username}
        </span>
        <span className="divider">|</span>
        <span className="company-name">
          <CompanyChooser user={current_user} />
        </span>
        <span className="divider">|</span>
        <a href="/logout">Logout</a>
        {this.appLink('royalty_portal', 'Royalty Portal')}
        {this.appLink('royalty_tracker', 'Royalty Tracker')}
      </div>
    );
  }

  loginLink() {
    return (<p>
      <a href="/auth/auth0">Login</a>
    </p>);
  }

  appLink(appName, label) {
    const { current_user, appLinks } = this.props;

    const hasAccess = current_user && (
      (appName === 'royalty_portal' && current_user.company_settings.royalty_portal_enabled)
      || (appName === 'royalty_tracker' && (current_user.administrator || current_user.customer_administrator))
    );

    return [
      <span key={`app-divider-${appName}`} className="divider">|</span>,
      <span key={`app-link-${appName}`}>
        {hasAccess ? <a href={appLinks[appName]} rel="noreferrer" target="_blank">{label}</a> : label}
      </span>
    ];
  }

  navigationBar() {
    const { currentPath, user_name, logoURL, navLinks, subLinks } = this.props;

    return (
      <Grid fluid className="rt-header">
        <Row>
          <span className="col-md-6" >
            <img className="logo" src={logoURL} alt="" />
          </span>
          <span className="col-md-6 user-bar">
            {user_name !== null ? this.detailsAndLogout() : this.loginLink()}
          </span>
        </Row>

        <Navbar key="primary" fluid className="navbar-rt-primary col-md-12">
          <Navbar.Collapse>
            <Nav key="links">
              {navLinks.map((urlSpec, index) =>
                <NavItem
                  key={index + 1}
                  eventKey={index + 1}
                  active={urlSpec.url === currentPath}
                  href={urlSpec.url}>
                  {urlSpec.label}
                </NavItem>)}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Navbar key="secondary" className="navbar-rt-secondary col-md-12" fluid>
          <Navbar.Collapse>
            <Nav className="nav-rt-secondary">
              {subLinks.map((urlSpec, index) =>
                <NavItem
                  key={index + 1}
                  eventKey={index + 1}
                  active={urlSpec.url === currentPath}
                  href={urlSpec.url}>
                  {urlSpec.label}
                </NavItem>)}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Grid>
    );
  }

  render() {
    return (
      this.navigationBar()
    );
  }
}

export default Navigation
