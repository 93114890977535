import React from "react";
import { FormControl } from "react-bootstrap";
import { INVALID_COLUMN_NUMBER, VALUE_NONE } from "../../constants";

export function numberToColumnName(input, header) {
  if (!Number.isInteger(input) || input == INVALID_COLUMN_NUMBER) return 'Column is not present in the file.';

  const baseLetterCode = "A".charCodeAt(0);
  let result = "";
  let number = input;

  while (number >= 0) {
    const code = number % 26; // get the last digit
    const letter = String.fromCharCode(baseLetterCode + code); // map it to a letter

    result = letter + result; // append to the result
    number = Math.floor(number / 26); // remove the last digit

    number--;
  }

  if (header) {
    return `Column #${input + 1}, ${result} or ${header}`;
  } else {
    return `Column #${input + 1} or ${result}`;
  }
}

export function lookupTableView(label, lookup_columns) {
  const { lookup_key_columns, lookup_key_values } = lookup_columns.find(({ key }) => key === label);

  return (
    <tr>
      <td>
        <b>{label} (Lookup Table)</b>
      </td>
      <td>
        <table className="table-with-spacing">
          <thead>
            <tr>
              {lookup_key_columns.map((column, index) => (
                <th key={index}>{numberToColumnName(parseInt(column, 10))}</th>
              ))}
              <th>{label} to Use</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(lookup_key_values).map(([key, rowValue], index) => (
              <tr key={index}>
                {key.split(',').map((value, subIndex) => (
                  <td key={subIndex}>{value}</td>
                ))}
                <td>{rowValue}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </td>
    </tr>
  )
}

class InputColumn extends React.Component {
  optionValues(name, index) {
    return <option key={index} value={index}>{name} ({numberToColumnName(index)})</option>
  }

  render() {
    const value = this.props.value != undefined ? this.props.value : INVALID_COLUMN_NUMBER;

    if (this.props.addNoneSelection) {
      return (
        <FormControl componentClass="select" value={value} onChange={this.props.setColumn}>
          <option hidden value={INVALID_COLUMN_NUMBER}>Select a column</option>
          <option value={VALUE_NONE}>No selection</option>
          {this.props.columnNames.map((item, index) => this.optionValues(item, index))}
        </FormControl>
      )
    } else {
      return (
        <FormControl componentClass="select" value={value} onChange={this.props.setColumn}>
          <option hidden value={INVALID_COLUMN_NUMBER}>Select a column</option>
          {this.props.columnNames.map((item, index) => this.optionValues(item, index))}
        </FormControl>
      )
    }
  }
}

export default InputColumn
