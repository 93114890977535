import React from "react";
import { FormControl, Well } from "react-bootstrap";
import { DEDUCTION_TYPE_NO, DEDUCTION_TYPE_WHOLE } from "../../../constants";

class SalesMappingStep12a extends React.Component {
  render() {
    const { deductionType, salesDeductionRate } = this.props;

    if (DEDUCTION_TYPE_WHOLE === deductionType) {
      return (
        <Well>
          What is the % to apply to the whole file?
          <FormControl
            key="deduction"
            type="number"
            min={0.0}
            max={100.0}
            step={0.01}
            onChange={this.props.setSalesDeductionEvent}
            value={salesDeductionRate}
          />
          After typing in your amount, please hit the Next button.
        </Well>
      );
    } else {
      if (DEDUCTION_TYPE_NO === deductionType) {
        return (
          <div>
            OK, please hit the Next button
            <br />
            &nbsp;
          </div>
        );
      } else {
        return <div></div>;
      }
    }
  }
}

export default SalesMappingStep12a;
