import EventBus from "eventing-bus";

export default function LoadCurrenciesFromSales(params) {
  return fetch(
    "/revenue_files/load_currencies",
    {
      method: "post",
      headers: {
        Accept: "application/json",
        'Content-type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify(params)
    }
  ).then((response) => {
    switch (response.status) {
      case 200:
        return response.json();
        break;
      case 503:
        EventBus.publish("backendTimedOut");
        break;
      default:
        EventBus.publish("loadCurrenciesUnexpectedError");
        break;
    }
  });
}
