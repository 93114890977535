import React from 'react'
import PropTypes from "prop-types"
import { Panel, Table } from "react-bootstrap";
import classNames from "classnames";

class SalesFileDisplay extends React.Component {
  constructor(props) {
    super(props);

    this.renderBodyRow = this.renderBodyRow.bind(this);
    this.renderBodyItem = this.renderBodyItem.bind(this);
  }

  renderBodyRow(row, row_index){
    return(
      <tr key={row_index}>
        {row.map((item, column_index) => this.renderBodyItem(item, column_index, row_index))}
      </tr>
    )
  }

  renderBodyItem(item, column_index, row_index){
    const { highlightColumn, highlightRow } = this.props;
    let highlightColumns = typeof highlightColumn === 'number' || typeof highlightColumn === 'string' ?
                             [highlightColumn] : [];

    return(
      <td key={row_index.toString() + '-' + column_index.toString()}
          className={ classNames({ info: highlightColumns.includes(column_index) || highlightRow === row_index, 'preview-file-header': row_index === 0 })}>
        {item}
      </td>
    )
  }

  render(){
    const { fileData } = this.props;

    return (
      <Panel header={<div>{"Sales File"}</div>} >
        <div style={{ maxWidth: "100%", maxHeight: "300px", overflowX: "scroll", whiteSpace: "nowrap"}}>
            <Table bordered condensed>
                <tbody>
                  { fileData.map((row, index) => { return this.renderBodyRow(row, index) }) }
                </tbody>
            </Table>
        </div>
      </Panel>
    )
  }
}

export default SalesFileDisplay
