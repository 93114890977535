import React from "react";
import { Well } from "react-bootstrap";
import InputColumn from "../InputColumn";

class SalesMappingStep11c extends React.Component {
  render() {
    const { currencyInFile, conversionRate } = this.props;

    if (currencyInFile === true && conversionRate !== null) {
      return (
        <Well>
          <p>What column is the <b>{conversionRate ? 'conversion rate' : 'currency code'}</b> column?</p>

          <InputColumn
            columnNames={this.props.columnNames}
            value={this.props.value}
            setColumn={this.props.setCurrencyCodeColumn}
            addNoneSelection={false}
          />
        </Well>
      );
    } else {
      if (conversionRate === true) {
        return (
          <div>
            We'll allow you to tell us what currency conversion rate to use for
            the file at the time of import.
            <br /> &nbsp;
          </div>
        );
      } else if (currencyInFile === false) {
        return (
          <div>
            We'll allow you to tell us what currency the file is in at the time
            of import.
            <br /> &nbsp;
          </div>
        );
      } else {
        return <div></div>;
      }
    }
  }
}

export default SalesMappingStep11c;
