import EventBus from "eventing-bus";

export default function DeleteRevenueFile(file_uuid) {
  return fetch(`/revenue_files/${file_uuid}.json`, {
    method: 'delete',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }).then(response => {
    switch (response.status) {
      case 200:
      case 201:
        response.json().then(
          json  => EventBus.publish("deleteRevenueFileRequestedSuccessful", json)
        );
        break;
      case 400:
      case 404:
      case 500:
        response.json().then(
          json  => EventBus.publish("deleteRevenueFileRequestedUnsuccessful", json)
        );
        break;
      default:
        EventBus.publish("deleteRevenueFileUnexpectedError");
        break;
    }
  });
}
