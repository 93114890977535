import React from 'react';
import { Select } from 'antd';

class CompanyChooser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: props.user.companies,
      value: props.user.company_id,
    }

    this.lookupTimeout = null;
    this.lookupDelay = 300;
    this.onSearch = this.onSearch.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  serverLookup(value, callback) {
    const params = new URLSearchParams({ company_name: value });

    fetch(`/login/search_company.json?${params}`)
      .then((response) => response.json())
      .then((data) => callback(data.companies));
  };

  onSearch = value => {
    clearTimeout(this.lookupTimeout);

    this.lookupTimeout = setTimeout(() =>
      this.serverLookup(
        value,
        (options) => this.setState({ options })
      ), this.lookupDelay
    );
  };

  onChange(value) {
    this.setState({ value });
    window.location.href = `/login/change_company?tenant_id=${value}`;
  }

  render() {
    const { user } = this.props;
    const { options, value } = this.state;

    if (user.companies.length <= 1) {
      return <span>{user.company_name}</span>;
    }

    return (
      <Select
        className="company-name"
        showSearch={true}
        defaultActiveFirstOption={false}
        filterOption={false}
        value={value}
        onSearch={this.onSearch}
        onChange={this.onChange}
      >
        {options.map(({ id, company_name }) => (
          <Select.Option key={id} value={id} title={company_name}>{company_name}</Select.Option>
        ))}
      </Select>
    );
  }
}

export default CompanyChooser;
