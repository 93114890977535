import React from "react";
import { Button, Panel, Table, Well } from "react-bootstrap";
import { DATE_FORMATS, VALUE_NONE } from "../../../constants";
import { lookupTableView, numberToColumnName } from "../InputColumn";
import { REVENUE_TYPE_KEY } from "../../../Utilities/Components/LookupTable";

class SalesMappingSummary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      saveSubmitted: false,
    };

    this.displayColumn = this.displayColumn.bind(this);
    this.displayDate = this.displayDate.bind(this);
    this.displayRevenueType = this.displayRevenueType.bind(this);
    this.displayCurrency = this.displayCurrency.bind(this);
    this.displayTargetCurrency = this.displayTargetCurrency.bind(this);
    this.displayCurrencyConversionImport = this.displayCurrencyConversionImport.bind(this);
    this.displayConversionRateImport = this.displayConversionRateImport.bind(this);
    this.displayCurrencyDate = this.displayCurrencyDate.bind(this);
    this.displaySalesDeduction = this.displaySalesDeduction.bind(this);
    this.displayHeaderRow = this.displayHeaderRow.bind(this);
    this.submitSave = this.submitSave.bind(this);
    this.submitUpdate = this.submitUpdate.bind(this);
    this.saveOrUpdateButton = this.saveOrUpdateButton.bind(this);
    this.displaySkipRow = this.displaySkipRow.bind(this);
  }

  displayColumn(name, columnNumber) {
    const { fileData, headerRow } = this.props;

    if (VALUE_NONE === columnNumber) {
      return (
        <tr>
          <td>
            <b>{name}</b>
          </td>
          <td>Column is not present in the file.</td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td>
            <b>{name}</b>
          </td>
          <td>
            {numberToColumnName(columnNumber, fileData[headerRow][columnNumber])}
          </td>
        </tr>
      );
    }
  }

  displayHeaderRow(row) {
    return (
      <tr>
        <td>
          <b>Header Row</b>
        </td>
        <td>Header Row is row number {row + 1}</td>
      </tr>
    );
  }

  displayDate() {
    const { fileData, assignDate, transactionDateColumn } = this.props;

    if (assignDate === true) {
      return (
        <tr>
          <td>
            <b>Transaction Date</b>
          </td>
          <td>We will ask you at the time of import for the date.</td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td>
            <b>Transaction Date</b>
          </td>
          <td>
            {numberToColumnName(transactionDateColumn, fileData[0][transactionDateColumn])}
          </td>
        </tr>
      );
    }
  }

  displayDateFormat(){
    const { assign_date, dateFormat } = this.props;

    if (assign_date === true) {
      return (
        <tr>
          <td>
            <b>Date Format</b>
          </td>
          <td>N/A</td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td>
            <b>Date Format</b>
          </td>
          <td>{DATE_FORMATS[dateFormat]}</td>
        </tr>
      );
    }
  }

  displayRevenueType() {
    const {
      assignRevenueTypeField,
      revenueTypeField,
      revenueTypes,
      lookUpRevenueTypeID,
      lookupColumns,
      lookupProcessingEnabled,
    } = this.props;

    if (assignRevenueTypeField) {
      return (
        <tr>
          <td>
            <b>Revenue Type</b>
          </td>
          <td>
            We will assign the revenue type, '{revenueTypeField}', to all transactions in this file.
          </td>
        </tr>
      );
    } else {
      if (lookUpRevenueTypeID) {
        return (
          <tr>
            <td>
              <b>Revenue Type</b>
            </td>
            <td>We will alook up the Product ID to determine Revenue Type.</td>
          </tr>
        );
      } else if (lookupProcessingEnabled && lookupColumns && lookupColumns.length > 0) {
        return lookupTableView(REVENUE_TYPE_KEY, lookupColumns);
      } else {
        return this.displayColumn("Revenue Type", this.props.revenueTypeColumn);
      }
    }
  }

  displayAccountName() {
    const {
      accountNameField,
      accountNameColumn,
    } = this.props;

    if (accountNameField !== null) {
      return (
        <tr>
          <td>
            <b>Account Name</b>
          </td>
          <td>We will set the Account Name to '{accountNameField}' for all records in this file.</td>
        </tr>
      );
    } else {
      return this.displayColumn("Account Name", accountNameColumn);
    }
  }

  displayCurrency() {
    const { currencyConversion, currencyInFile } = this.props;

    if (currencyConversion) {
      if (currencyInFile) {
        this.displayColumn(
          "Currency Conversion",
          this.props.currencyCodeColumn
        );
        this.displayCurrencyDate();
      } else {
        {
          this.displayCurrencyConversionImport();
        }
        {
          this.displayCurrencyDate();
        }
        {
          this.displayConversionRateImport();
        }
      }
    } else {
      return (
        <tr>
          <td>
            <b>Currency Conversion</b>
          </td>
          <td>No currency conversion is necessary.</td>
        </tr>
      );
    }
  }

  displayTargetCurrency() {
    const { currencyConversion, currencyInFile } = this.props;

    if (currencyConversion) {
      if (currencyInFile) {
        return (
          <tr>
            <td>
              <b>Target Currency Conversion</b>
            </td>
            <td>{this.props.targetCurrencyCode}</td>
          </tr>
        );
      }
    }

    return null;
  }

  displayCurrencyConversionImport() {
    return (
      <tr>
        <td>
          <b>Currency Conversion</b>
        </td>
        <td>We will ask what currency the file is in at the time of import.</td>
      </tr>
    );
  }

  displayConversionRateImport() {
    const { conversionRate } = this.props;

    return conversionRate && (
      <tr>
        <td>
          <b>Conversion Rate</b>
        </td>
        <td>We will ask what conversion rate the file is in at the time of import.</td>
      </tr>
    );
  }

  displayCurrencyDate() {
    const { specifyCurrencyDate } = this.props;

    if (specifyCurrencyDate) {
      return (
        <tr>
          <td>
            <b>Currency Conversion Date</b>
          </td>
          <td>We will ask what date to use for the currency conversion.</td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td>
            <b>Currency Conversion Date</b>
          </td>
          <td>
            We use the transaction date for the date of the currency conversion.
          </td>
        </tr>
      );
    }
  }

  displaySalesDeduction() {
    const { deductionColumn, salesDeductionRate, negativeDeductionSign } =
      this.props;

    if (null === deductionColumn || VALUE_NONE === deductionColumn) {
      if (salesDeductionRate == 0) {
        return (
          <tr>
            <td>
              <b>Sales Deduction</b>
            </td>
            <td>No sales deduction for this file.</td>
          </tr>
        );
      } else {
        return (
          <tr>
            <td>
              <b>Sales Deduction</b>
            </td>
            <td>We will deduct {salesDeductionRate}% from every sale.</td>
          </tr>
        );
      }
    } else {
      return this.displayColumn("Sales Deduction", this.props.deductionColumn);
    }
  }

  displaySkipRow() {
    const { skipRowProcessingEnabled } = this.props;
    const { skipMatchColumn, skipMatchExpression, useSkipRowProcessor } = this.props;

    // Hide this feature if it's disable AND it's not already in use for this mapping
    if (!skipRowProcessingEnabled && !useSkipRowProcessor) {
      return null;
    }

    let text = "Not in use";

    if (useSkipRowProcessor) {
      text = (<>Match value in column #{skipMatchColumn + 1} against text:<br/>{skipMatchExpression}</>);
    }

    return (
      <tr>
        <td>
          <b>Skip Rows</b>
        </td>
        <td>{text}</td>
      </tr>
    );
  }

  displaySelectedSheet(sheetNumber) {
    const num = (sheetNumber === null ? 0 : sheetNumber) + 1;

    return (
      <tr>
        <td>
          <b>Sheet</b>
        </td>
        <td>Sheet #{num}</td>
      </tr>
    );
  }

  submitSave() {
    this.setState({ saveSubmitted: true });
    this.props.createMapping();
  }

  submitUpdate() {
    this.setState({ saveSubmitted: true });
    this.props.updateMapping();
  }

  saveOrUpdateButton() {
    const { editingMapping } = this.props;
    if (editingMapping) {
      return (
        <Button
          key="updateMapping"
          bsStyle="primary"
          className="pull-right"
          disabled={this.state.saveSubmitted}
          onClick={this.submitUpdate}
        >
          Update
        </Button>
      );
    } else {
      return (
        <Button
          key="saveMapping"
          bsStyle="primary"
          className="pull-right"
          disabled={this.state.saveSubmitted}
          onClick={this.submitSave}
        >
          Save
        </Button>
      );
    }
  }

  render() {
    return (
      <Panel header={<div>Summary</div>}>
        <div>
          Let's review the file template. If it looks good, click save. To make
          changes, use the Back button.
          <div className="pull-right">{this.saveOrUpdateButton()}</div>
          <div className="pull-right">
            <Button
              key="previousPage"
              bsStyle="primary"
              className="pull-right"
              onClick={this.props.previousPage}
            >
              Back
            </Button>
          </div>
          <br />
          &nbsp;
          <Panel header={<div>{this.props.name}</div>}>
            <Well className="mappingNotes">
              <p>
                <b>Notes</b>
              </p>
              {this.props.notes}
            </Well>
            <Table bordered condensed>
              <tbody>
                {this.displaySelectedSheet(this.props.sheetNumber)}
                {this.displayHeaderRow(this.props.headerRow)}
                {this.displaySkipRow()}
                {this.displayColumn("Product ID", this.props.productIDColumn)}
                {this.displayDate()}
                {this.displayDateFormat()}
                {this.displayColumn(
                  "Gross Units Sold",
                  this.props.grossUnitsSoldColumn
                )}
                {this.displayColumn(
                  "Units Returned",
                  this.props.unitsReturnColumn
                )}
                {this.displayColumn(
                  "Net Units Sold",
                  this.props.netUnitsSoldColumn
                )}
                {this.displayColumn("List Price", this.props.listPriceColumn)}
                {this.displayColumn("Net Amount", this.props.netAmountColumn)}
                {this.displayColumn("Discount", this.props.discountColumn)}
                {this.displayColumn(
                  "Discount Rate",
                  this.props.discountRateColumn
                )}
                {this.displayRevenueType()}
                {this.displayColumn("Mfg Cost", this.props.mfgCostColumn)}
                {this.displayCurrency()}
                {this.displayTargetCurrency()}
                {this.displayCurrencyDate()}
                {this.displaySalesDeduction()}
                {this.displayColumn("Buyer", this.props.buyerColumn)}
                {this.displayColumn("Territory", this.props.territoryColumn)}
                {this.displayAccountName(
                  "Account Name",
                  this.props.accountNameColumn
                )}
                {this.displayColumn(
                  "Invoice Number",
                  this.props.invoiceNumberColumn
                )}
                {this.displayColumn("Author Name", this.props.authorNameColumn)}
                {this.displayColumn(
                  "Project Name",
                  this.props.projectNameColumn
                )}
                {this.displayColumn("Format", this.props.formatColumn)}
                {this.displayColumn("Misc 1", this.props.misc1Column)}
                {this.displayColumn("Misc 2", this.props.misc2Column)}
                {this.displayColumn("Misc 3", this.props.misc3Column)}
                {this.displayColumn("Misc 4", this.props.misc4Column)}
                {this.displayColumn("Misc 5", this.props.misc5Column)}
              </tbody>
            </Table>
          </Panel>
        </div>
        <div className="pull-right">{this.saveOrUpdateButton()}</div>

        <div className="pull-right">
          <Button
            key="previousPage"
            bsStyle="primary"
            className="pull-right"
            onClick={this.props.previousPage}
          >
            Back
          </Button>
        </div>
      </Panel>
    );
  }
}

export default SalesMappingSummary;
