import React from "react";
import { Button, Panel } from "react-bootstrap";

class SalesMappingStart extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Panel header={<div>File Templates</div>}>
          <p>
            Sales Aggregator makes it easy to import sales from all of your
            retailers. Create a template for each file type that you receive.
            This tool will guide you through the process.
          </p>

          <div className="pull-right">
            <Button
              key="nextPage"
              bsStyle="primary"
              onClick={this.props.nextPage}
            >
              Create New Template
            </Button>
          </div>
        </Panel>
      </div>
    );
  }
}

export default SalesMappingStart;
