import EventBus from "eventing-bus";

export default function ImportSalesWithMapping(dirty_params) {
  const params = {
    ...dirty_params,
    assigned_currency_date: dirty_params.assigned_currency_date
      .toISOString(true)
      .split("T")[0],
    assigned_date: dirty_params.assigned_date.toISOString(true).split("T")[0],
  };

  return fetch(
    "/revenue_files",
    {
      method: "post",
      headers: {
        Accept: "application/json",
        'Content-type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify(params)
    }
  ).then((response) => {
    switch (response.status) {
      case 200:
        response
          .json()
          .then((json) =>
            EventBus.publish("importSalesWithMappingStarted", json)
          );
        break;
      case 503:
        EventBus.publish("backendTimedOut");
        break;
      default:
        EventBus.publish("importRevenuesCsvUnsuccessful");
        break;
    }
  });
}
