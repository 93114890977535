import React from 'react'
import PropTypes from "prop-types"
import { Button, Panel, Col, ListGroup, ListGroupItem, Row, Well } from "react-bootstrap";

class ImportSalesStart extends React.Component {
  constructor(props) {
    super(props);

    this.onMappingSelected = this.onMappingSelected.bind(this);
    this.mappingItem = this.mappingItem.bind(this);
    this.renderActiveMapping = this.renderActiveMapping.bind(this);
  }

  onMappingSelected(mapping) {
    return event => {
      event.preventDefault();
      this.props.selectMapping(mapping);
    };
  }

  mappingItem(mapping) {
    const activeMapping = this.props.activeMapping || {};
    const itemAttributes = (activeMapping.uuid === mapping.uuid) ?
      { key: mapping.uuid,
        active: true } :
      { key: mapping.uuid,
        href: '#', onClick: this.onMappingSelected(mapping) };

    if (this.props.showArchived || !mapping.archived ) {
      return (<ListGroupItem {...itemAttributes}>
        {mapping.name}
      </ListGroupItem>);
    }
  }

  renderActiveMapping() {
    const { activeMapping } = this.props;

    if (activeMapping === null) {
      return null;
    }

    return (
      <div>
        <Well className="mappingNotes">
          <p><b>Notes</b></p>
          {activeMapping.notes}
        </Well>
        <div className="pull-right">
          <Button key='nextPage' bsStyle="primary" disabled={this.props.activeMapping.archived} onClick={this.props.nextPage}>Next</Button>
        </div>
      </div>
    );
  }

  render() {
    const { mappings } = this.props;

    return(
      <div>
        <Row>
          <Col md={2}></Col>
          <Col md={8}>
            <Panel header={<div>Import Sales</div>}>
              <p>
                This tool is used to import sales. Using the file template, we can map sales files into a specific format.
                First, we need to choose a template for the file you want to import.
                If no template matches your sales file, you will need to create a new template.
              </p>
            </Panel>
          </Col>
          <Col md={2}></Col>
        </Row>

        <Row>
          <Col md={6}>
            <Panel header={<div>Templates</div>}>
              <ListGroup key="mapping-list" className="util-scrollable-list">
                {mappings.map(this.mappingItem)}
              </ListGroup>
              <Button key='toggleArchived' bsSize="small" onClick={this.props.toggleArchived}>{this.props.showArchived ? "Hide Archived" : "Show Archived"}</Button>
            </Panel>
          </Col>
          <Col md={6}>
          <Panel header={<div>Template Info</div>}>
            {this.renderActiveMapping()}
          </Panel>
          </Col>
        </Row>
      </div>
    )
  }

}

export default ImportSalesStart
