import React from "react";
import { Button, Panel, Col, FormControl, Row } from "react-bootstrap";
import { DatePicker } from "antd";
import moment from "moment";
import CurrencyCodeChooser from "../../../Utilities/Components/CurrencyCodeChooser";

class ImportSalesStep1 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dateSet: false,
      currencyDateSet: false,
      currencyCodeSet: false,
    };

    this.colNumber = this.colNumber.bind(this);
    this.assignDate = this.assignDate.bind(this);
    this.assignCurrency = this.assignCurrency.bind(this);
    this.assignCurrencyDate = this.assignCurrencyDate.bind(this);
    this.optionValues = this.optionValues.bind(this);
  }

  optionValues(name, index){
    return <option key={index} value={index}>{name}</option>
  }

  colNumber() {
    const { activeMapping } = this.props;
    let number = 12;

    if (activeMapping.assign_date === true) {
      number = number - 4;
    }
    if (activeMapping.currency_in_file === false) {
      number = number - 4;
    }
    if (activeMapping.specify_currency_date === true) {
      number = number - 4;
    }

    return number / 2;
  }

  assignDate() {
    const { activeMapping, assignedDate } = this.props;
    const dateFormat = "MM/DD/YYYY";

    if (activeMapping.assign_date === true) {
      return (
        <Col md={4}>
          <Panel header={<div>Assign Date for Sales</div>}>
            <div>
              <p>
                This template requires you to enter in a date for each
                transaction.
              </p>
              <p>What date do you want to use?</p>

              <div>
                <DatePicker
                  defaultValue={moment(assignedDate)}
                  format={dateFormat}
                  onChange={(date) => this.props.changeRevenueDate(date)}
                />
              </div>
            </div>
          </Panel>
        </Col>
      );
    }
  }

  assignCurrency() {
    const { activeMapping } = this.props;

    if (
      activeMapping.currency_in_file === false &&
      activeMapping.conversion_rate === false
    ) {
      return (
        <Col md={4}>
          <Panel header={<div>Assign Currency Code</div>}>
            <div>
              <p>
                This template requires you to specify a currency code for the
                currency conversion. This means that the sales file is in
                another currency.
              </p>

              <p>What currency is in this file?</p>

              <div>
                <CurrencyCodeChooser
                  defaultValue={this.props.assignedCurrencyCode}
                  onChange={this.props.setCurrencyCode}
                />
              </div>

              <p>What is the target currency?</p>

              <div>
                <CurrencyCodeChooser
                  value={this.props.assignedTargetCurrencyCode}
                  onChange={this.props.setTargetCurrencyCode}
                />
              </div>
            </div>
          </Panel>
        </Col>
      );
    }
  }

  assignConversationRate() {
    const { activeMapping } = this.props;

    if (
      activeMapping.currency_in_file === false &&
      activeMapping.conversion_rate === true
    ) {
      return (
        <Col md={4}>
          <Panel header={<div>Assign Rate for Currency Conversion</div>}>
            <div>
              <p>
                This template requires you to enter in a rate for the currency
                conversion.
              </p>

              <p>What rate do you want to use?</p>

              <div>
                <FormControl
                  type="number"
                  min={0.0}
                  max={100.0}
                  step={0.01}
                  onChange={(e) =>
                    this.props.changeConversionRate(e.target.value)
                  }
                />
              </div>
            </div>
          </Panel>
        </Col>
      );
    }
  }

  assignCurrencyDate() {
    const { activeMapping, assignedCurrencyDate } = this.props;

    const dateFormat = "MM/DD/YYYY";

    if (activeMapping.specify_currency_date === true) {
      return (
        <Col md={4}>
          <Panel header={<div>Assign Date for Currency Conversion</div>}>
            <div>
              <p>
                This template requires you to enter in a date for the currency
                conversion.
              </p>
              <p>What date do you want to use?</p>

              <div>
                <DatePicker
                  defaultValue={assignedCurrencyDate}
                  format={dateFormat}
                  onChange={(date) => this.props.changeCurrencyDate(date)}
                />
              </div>
            </div>
          </Panel>
        </Col>
      );
    }
  }

  render() {
    return (
      <div>
        <Row>
          <Col md={2}></Col>
          <Col md={8}>
            <Panel header={<div>Import Sales</div>}>
              <p>
                A couple of things are needed before we are able to import your
                file.
              </p>
            </Panel>
          </Col>
          <Col md={2}></Col>
        </Row>
        <Row>
          <Col md={this.colNumber()}></Col>
          {this.assignDate()}
          {this.assignCurrency()}
          {this.assignConversationRate()}
          {this.assignCurrencyDate()}
          <Col md={this.colNumber()}></Col>
        </Row>
        <div className="pull-right">
          <Button
            key="nextPage"
            bsStyle="primary"
            onClick={this.props.nextPage}
          >
            Next
          </Button>
        </div>
      </div>
    );
  }
}

export default ImportSalesStep1;
