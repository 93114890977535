import React from 'react'
import ImportSalesStart from "./Steps/ImportSalesStart";
import ImportSalesStep1 from "./Steps/ImportSalesStep1";
import ImportSalesStep2 from "./Steps/ImportSalesStep2";
import { Alert } from "react-bootstrap";
import $ from "jquery";
import 'whatwg-fetch'
import ImportSalesWithMapping from "../../ImportSalesWithMapping"
import LoadCurrenciesFromSales from "../../LoadCurrenciesFromSales"
import EventBus from "eventing-bus"
import moment from "moment";

const DEFAULT_CURRENCY_CODE = 'USD';

class ImportSalesView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.initialState(),
      alert: "",
      alertTable: null,
      alertVisible: false,
      alertClass: "",
      subscriptions: [],
      mappings: [],
      showArchived: false,
    };

    this.changeRevenueDate = this.changeRevenueDate.bind(this);
    this.changeCurrencyDate = this.changeCurrencyDate.bind(this);
    this.changeConversionRate = this.changeConversionRate.bind(this);
    this.selectMapping = this.selectMapping.bind(this);
    this.setCurrencyCode = this.setCurrencyCode.bind(this);
    this.setTargetCurrencyCode = this.setTargetCurrencyCode.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.setPageToStart = this.setPageToStart.bind(this);
    this.sendFile = this.sendFile.bind(this);
    this.loadCurrencies = this.loadCurrencies.bind(this);
    this.renderPage = this.renderPage.bind(this);
    this.displaySuccessMessage = this.displaySuccessMessage.bind(this);
    this.displayErrorMessage = this.displayErrorMessage.bind(this);
    this.restartTheImport = this.restartTheImport.bind(this);
    this.handleAlertDismiss = this.handleAlertDismiss.bind(this);
    this.toggleArchived = this.toggleArchived.bind(this);
    this.markAsArchived = this.markAsArchived.bind(this);
    this.deleteMapping = this.deleteMapping.bind(this);
    this.requeryMappings = this.requeryMappings.bind(this);
    this.anyMappingQuestions = this.anyMappingQuestions.bind(this);
  }

  requeryMappings(){
    $.ajax({
      url: "/mappings",
      type: "get",
      statusCode: {
        200: (response) => {
          this.setState({
            mappings: response
          });
        }
      }
    });
  }

  componentWillMount() {
    this.requeryMappings();

    const onUploadRevenuesFileSuccessful = EventBus.on("uploadRevenuesFileSuccessful", json => {
       const text = `File upload completed.`;

       this.displaySuccessMessage(text, '');
    });

    const onImportSalesWithMappingStarted = EventBus.on("importSalesWithMappingStarted", json => {
       const text = `Importing the sales file... Navigate to the Sales File tab to check the status or import another file.`;

       this.displaySuccessMessage(text, '');

       this.restartTheImport();
    });

    const onImportSuccessful = EventBus.on("importRevenuesCsvSuccessful", importSummary => {
      const text = `
        Import completed.
        Rows succeeded: ${ importSummary.success_count }.
        Rows failed: ${ importSummary.failure_count }.`;

      this.displaySuccessMessage(text, importSummary.feedbacks);
    });

    const onImportUnsuccessful = EventBus.on("importRevenuesCsvUnsuccessful", response => {
      this.displayErrorMessage(response.errors, null);
    });

    const onLoadCurrenciesUnsuccessful = EventBus.on("loadCurrenciesUnexpectedError", response => {
      this.displayErrorMessage("Sorry, but it seems like the currency codes for the file you selected are currently inaccessible. You may want to try using a different file or attempt again at a later time.", null);
    });

    const onBackendTimedOut = EventBus.on("backendTimedOut", () => {
      this.setState({
        alert: <p>Your request is taking too long to process. This might be due to a large file size, a slow network connection, or a high server load. One way to reduce your file size is to split it into multiple parts. Please also check your network connection, and upload your file again. Please contact <a href="mailto:support@metacomet.com">support@metacomet.com</a> for further assistance.</p>,
        alertTable: [],
        alertVisible: true,
        alertClass: "warning",
      })
    });

    this.state.subscriptions.push(
      onUploadRevenuesFileSuccessful, onImportSalesWithMappingStarted,
      onImportSuccessful, onImportUnsuccessful, onLoadCurrenciesUnsuccessful, onBackendTimedOut);
  }

  sendFile(filename, fileData, conversionRates){
    EventBus.publish('uploadRevenuesFileSuccessful',{});
    ImportSalesWithMapping({
      filename,
      original_file: fileData,
      mapping_uuid: this.state.activeMapping.uuid,
      assigned_currency_code: this.state.assignedCurrencyCode,
      assigned_target_currency_code: this.state.assignedTargetCurrencyCode,
      assigned_currency_date: this.state.assignedCurrencyDate,
      assigned_date: this.state.assignedDate,
      assigned_conversion_rate: this.state.assignedConversionRate,
      conversion_rates: conversionRates
    });
  }

  loadCurrencies(filename, fileData){
    return LoadCurrenciesFromSales({
      filename,
      original_file: fileData,
      mapping_uuid: this.state.activeMapping.uuid
    });
  }

  handleAlertDismiss() {
    this.setState({
      alertVisible: false,
    });
  }

  componentWillUnmount(){
    this.state.subscriptions.map(sub => sub());
  }

  displayErrorMessage(text, alertTable) {
    this.setState({
        alert: text,
        alertTable: alertTable,
        alertVisible: true,
        alertClass: "danger",
    })
  }

  displaySuccessMessage(text, alertTable) {
      this.setState({
          alert: text,
          alertTable: alertTable,
          alertVisible: true,
          alertClass: "success",
      })
  }

  alertWindow() {
    const { alert, alertClass } = this.state;

    return (
      <Alert bsStyle={alertClass} onDismiss={this.handleAlertDismiss}>
        {alert}
      </Alert>
    );
  }

  setCurrencyCode(event){
    this.setState({
      assignedCurrencyCode: event.target.value
    })
  }

  setTargetCurrencyCode(event){
    this.setState({
      assignedTargetCurrencyCode: event.target.value
    })
  }

  changeRevenueDate(date){
    this.setState({
      assignedDate: moment(date)
    })
  }

  changeCurrencyDate(date){
    this.setState({
      assignedCurrencyDate: moment(date)
    })
  }

  changeConversionRate(value){
    this.setState({
      assignedConversionRate: value
    })
  }

  selectMapping(mapping) {
      this.setState({
        activeMapping: mapping
      })
  }

  nextPage() {
    let { page } = this.state;

    if (page == 0 && !this.anyMappingQuestions()) {
      page = page + 1;
    }

    this.setState({
      page: page + 1,
    })
  }

  setPageToStart() {
    this.setState({
      page: 0,
    })
  }

  restartTheImport(){
    this.setState(this.initialState());
  }

  anyMappingQuestions() {
    const { activeMapping } = this.state;
    return false
      || activeMapping.assign_date === true
      || activeMapping.currency_in_file === false
      || activeMapping.specify_currency_date === true;
  }

  toggleArchived(){
    this.setState({
      showArchived: !this.state.showArchived
    })
  }

  markAsArchived(){
    $.ajax({
      url: `/mappings/${this.state.activeMapping.uuid}/archive`,
      type: "POST",
      data: {},
      statusCode: {
        200: (response) => {
          this.setState({
            alert: "Template was archived successfully.",
            alertVisible: true,
            alertSuccessful: true,
          });
          this.requeryMappings();
        },
        500: () => {
          this.setState({
            alert: "Template was not archived successfully.",
            alertVisible: true,
            alertSuccessful: true,
          });
        },
        503: () => {
          this.setState({
            alert: "Template is taking a longer time to update.",
            alertVisible: true,
            alertSuccessful: true,
          });
        }
      }
    });
  }

  deleteMapping(){
    $.ajax({
      url: "/mappings/" + this.state.activeMapping.uuid,
      type: "DELETE",
      data: {},
      statusCode: {
        200: (response) => {
          this.setState({
            alert: "Template was deleted successfully.",
            alertVisible: true,
            alertSuccessful: true,
            activeMapping: null,
          });
          this.requeryMappings();
        },
        500: () => {
          this.setState({
            alert: "Template was not deleted successfully.",
            alertVisible: true,
            alertSuccessful: true,
          });
        }
      }
    })
  }

  renderPage(){
    switch(this.state.page) {
      case 0:
        return <ImportSalesStart
          nextPage={ this.nextPage }
          mappings={ this.state.mappings }
          activeMapping={ this.state.activeMapping }
          revenueTypes={ this.props.revenueTypes }
          selectMapping={this.selectMapping}
          deleteMapping={ this.deleteMapping }
          toggleArchived={ this.toggleArchived }
          showArchived={ this.state.showArchived }
          markAsArchived={ this.markAsArchived }
        />;
      case 1:
        return <ImportSalesStep1
          nextPage={ this.nextPage }
          activeMapping={ this.state.activeMapping }
          assignedDate={ this.state.assignedDate }
          assignedCurrencyDate={ this.state.assignedCurrencyDate }
          assignedCurrencyCode={ this.state.assignedCurrencyCode }
          assignedTargetCurrencyCode={ this.state.assignedTargetCurrencyCode }
          assignedConversionRate={ this.state.assignedConversionRate }
          changeRevenueDate={ this.changeRevenueDate }
          changeCurrencyDate={ this.changeCurrencyDate }
          changeConversionRate={ this.changeConversionRate }
          setCurrencyCode={this.setCurrencyCode}
          setTargetCurrencyCode={this.setTargetCurrencyCode}
        />;
      case 2:
        return <ImportSalesStep2
          sendFile={ this.sendFile }
          loadCurrencies={ this.loadCurrencies }
          activeMapping={ this.state.activeMapping }
          assignedCurrencyDate={ this.state.assignedCurrencyDate }
          setPageToStart={this.setPageToStart}
        />;
    }
  }

  render(){
    return (
      <div>
        {this.state.alertVisible ? this.alertWindow() : null}
        {this.renderPage()}
      </div>
    )
  }

  initialState(){
    {
      return {
        activeMapping: null,
        page: 0,
        assignedDate: moment(),
        assignedCurrencyDate: moment(),
        assignedCurrencyCode: DEFAULT_CURRENCY_CODE,
        assignedTargetCurrencyCode: DEFAULT_CURRENCY_CODE,
        assignedConversionRate: null
      };
    }
  }
}


export default ImportSalesView
