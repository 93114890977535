import React from 'react';
import { FormControl } from "react-bootstrap";

export default class FileUploadForm extends React.Component {

  constructor(props) {
    super(props);
    this.fileChosen = this.fileChosen.bind(this);
  }

  fileChosen() {
    const formData = new FormData(this.refs.uploadForm);
    this.props.onFileChosen(formData);
  }

  render() {
    return(
      <div>
        <form ref="uploadForm" encType="multipart/form-data">
          <FormControl name="file" type="file" onChange={this.fileChosen} />
        </form>
      </div>
    );
  }

}