import React from "react";
import { Button, Well } from "react-bootstrap";

class SalesMappingStep11a extends React.Component {
  constructor(props) {
    super(props);

    this.useConversionRate = this.useConversionRate.bind(this);
    this.notUseConversionRate = this.notUseConversionRate.bind(this);
  }

  useConversionRate() {
    this.props.setConversionRate(true);
  }

  notUseConversionRate() {
    this.props.setConversionRate(false);
  }

  render() {
    const { currencyInFile, conversionRate } = this.props;

    if (currencyInFile !== null) {
      return (
        <Well>
          <div>
            Is there a specific conversion rate for the whole file, or would you
            like us to calculate the rate based on currency code?
            <br /> &nbsp;
            <div>
              <Button
                key="assignCurrencyCodeInFileYes"
                bsSize="small"
                bsStyle={conversionRate === true ? "primary" : "default"}
                onClick={this.useConversionRate}
              >
                Specify a currency{" "}
                <b>
                  conversion rate
                </b>
              </Button>
              &nbsp;
              <Button
                key="assignCurrencyCodeInFileNo"
                bsSize="small"
                bsStyle={conversionRate === false ? "primary" : "default"}
                onClick={this.notUseConversionRate}
              >
                Specify a currency <b>code</b>
              </Button>
            </div>
          </div>
        </Well>
      );
    } else {
      return <div></div>;
    }
  }
}

export default SalesMappingStep11a;
