import React from "react";
import { Button, Panel, FormControl } from "react-bootstrap";

class SalesMappingStep2a extends React.Component {

  render() {
    const { excelWorkbook, sheetNumber } = this.props;
    const sheets = excelWorkbook.SheetNames;

    return (
      <Panel header={<div>Sheet</div>}>
        <div>
          Select which sheet should be imported from the file. By default the
          first sheet is selected. <br /> &nbsp;
          <FormControl
            componentClass="select"
            defaultValue={sheetNumber}
            onChange={this.props.setSheetNumber}
          >
            {sheets.map((name, index) => {
              return (
                <option key={index} value={index}>
                  {name} (Sheet #{index + 1})
                </option>
              );
            })}
          </FormControl>
        </div>
        <div className="pull-left">
          <Button
            key="previousPage"
            bsStyle="primary"
            onClick={this.props.previousPage}
          >
            Back
          </Button>
        </div>
        <div className="pull-right">
          <Button
            key="nextPage"
            bsStyle="primary"
            onClick={this.props.nextPage}
          >
            Next
          </Button>
        </div>
      </Panel>
    );
  }
}

export default SalesMappingStep2a;
