import React from "react";

class SalesMappingStep11b extends React.Component {
  render() {
    const { currencyConversion } = this.props;

    if (currencyConversion === false) {
      return (
        <div>
          OK, no conversion is necessary.
          <br />
          &nbsp;
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default SalesMappingStep11b;
