import React from 'react';
import { Alert } from "react-bootstrap";
import $ from "jquery";
import moment from 'moment';
import { Table, Button, Tooltip } from 'antd';
import Icon, { DownloadOutlined, DeleteOutlined, ArrowRightOutlined} from '@ant-design/icons';
import Confirm from '../Utilities/Components/ConfirmModal';
import EventBus from "eventing-bus";
import DeleteRevenueFile from '../../adapters/DeleteRevenueFile';

class RevenueFiles extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      alert: "",
      alertVisible: false,
      alertClass: "",
      revenue_files: [],
      mappings: [],
      loading: true,
      subscriptions: []
    };

    this.pushRow = this.pushRow.bind(this);
    this.mapStatus = this.mapStatus.bind(this);
    this.fileImportCompleted = this.fileImportCompleted.bind(this);
    this.fileDeleteInProgress = this.fileDeleteInProgress.bind(this);
    this.loadRevenueFiles = this.loadRevenueFiles.bind(this);
    this.requestDeleteRevenueFile = this.requestDeleteRevenueFile.bind(this);
    this.sendToRoyaltyTracker = this.sendToRoyaltyTracker.bind(this);
    this.actionDisabled = this.actionDisabled.bind(this);
    this.canSendToRT = this.canSendToRT.bind(this);
    this.isSendingToRT = this.isSendingToRT.bind(this);
    this.sendToRTText = this.sendToRTText.bind(this);
    this.previouslySent = this.previouslySent.bind(this);
  }

  componentWillMount() {
      this.loadRevenueFiles();

      $.ajax({
          url: "/mappings",
          type: "get",
          statusCode: {
              200: (response) => {
                  this.setState({
                      mappings: response
                  });
              }
          }
      });

      const onDeleteRevenueFileRequestedSuccessful = EventBus.on("deleteRevenueFileRequestedSuccessful", json => {
          const file = this.state.revenue_files.find(({uuid}) => uuid === json.file_uuid);
          const text = `File ${file.file_name} will be soon deleted. Refresh the page to check the status.`;

          this.displaySuccessMessage(text);
          this.loadRevenueFiles();
      });

      const onDeleteRevenueFileRequestedUnsuccessful = EventBus.on("deleteRevenueFileRequestedUnsuccessful", json => {
          const text = `File can't be deleted, ensure that it was fully imported or that delete is not already in progress.`;

          this.displayErrorMessage(text);
          this.loadRevenueFiles();
      });

      this.state.subscriptions.push(onDeleteRevenueFileRequestedSuccessful, onDeleteRevenueFileRequestedUnsuccessful);
  }

  componentWillUnmount(){
    this.state.subscriptions.map(sub => sub());
  }

  displayErrorMessage(text) {
    this.setState({
        alert: text,
        alertVisible: true,
        alertClass: "danger",
    });
  }

  displaySuccessMessage(text) {
    this.setState({
        alert: text,
        alertVisible: true,
        alertClass: "success",
    });
  }

  alertWindow() {
    const { alert, alertClass } = this.state;

    return (<Alert bsStyle={alertClass}>{alert}</Alert>);
  }

  loadRevenueFiles(){
    $.ajax({
          url: "/revenue_files",
          type: "get",
          statusCode: {
              200: (response) => {
                  this.setState({
                      revenue_files: response,
                      loading: false
                  });
              }
          }
    });
  }

  sendToRoyaltyTracker(key) {
    const uuids = key ? [key] : [];
    $.ajax({
      url: "/revenue_files/transmit.json",
      type: "post",
      data: { uuids: uuids },
      statusCode: {
        200: (response) => {
          if (response.count > 0) {
            this.displaySuccessMessage(`Sending ${response.count} file(s)`);
          } else {
            this.displaySuccessMessage(`All files have already been sent`);
          };
          this.loadRevenueFiles();
        }
      }
    });
  }

  mapStatus(statusCode) {
    let message = "-";

    switch (statusCode) {
        case "UPLOADED":
            message = "In-progress (uploaded)";
            break;
        case "INPROGRESS":
            message = "In-progress (importing)";
            break;
        case "SUCCESS":
            message = "Success";
            break;
        case "FAILED":
            message = "Failed";
            break;
        case "DELETEREQUESTED":
            message = "Deleting";
            break;
        case "DELETING":
            message = "Deleting";
            break;
        case "DELETEFAILED":
            message = "Delete failed";
            break;
        case "SENDREQUESTED":
            message = "Sending";
            break;
        case "SENT":
            message = "Sent to Royalty Tracker";
            break;
        case "SENDFAILED":
            message = "Send failed";
            break;
        default:
            message = statusCode;
    }

    return message;
  }

  pushRow(rows, file){
    const { mappings } = this.state;
    let mapping = mappings.find(({ uuid }) => uuid === file.mapping_uuid)

    rows.push({key: file.uuid,
      file_name: file.file_name,
      sales_map: mapping ? mapping.name : file.mapping_uuid,
      status: file.status,
      failure_message: file.failure_message,
      status_display: this.mapStatus(file.status),
      imported: file.created_at,
      rows: file.rows,
      failed_rows: file.failed_rows,
      units: file.units,
      amount: file.amount,
    });
  }

  fileImportCompleted(status) {
    return status === 'SUCCESS' || status === 'FAILED' || status === 'DELETEFAILED' || this.previouslySent(status);
  }

  fileDeleteInProgress(status) {
    return status === 'DELETEREQUESTED' || status === 'DELETING';
  }

  actionDisabled(status) {
    return this.fileDeleteInProgress(status) || !this.fileImportCompleted(status)
  }

  canSendToRT(status) {
    return !this.isSendingToRT(status)
  }

  isSendingToRT(status) {
    return status === 'SENDREQUESTED'
  }

  previouslySent(status) {
    return status === 'SENT' || status === 'SENDFAILED'
  }

  sendToRTText(status) {
    const verb = this.previouslySent(status) ? 'Resend' : 'Send'

    return `${verb} formatted file to Royalty Tracker`
  }

  requestDeleteRevenueFile(key){
      return Confirm("Are you sure you want to delete this file and all associated data?",
          {yesStyle: "danger", header: "Confirm Deletion"}).then(
          () => {
              DeleteRevenueFile(key);
          });

  }

  render(){
    let dataSource = [];
    this.state.revenue_files.map(file => this.pushRow(dataSource, file));

    const uniqueDates = [...new Set(dataSource.map(file => file.imported))];
    let dateFilters = [];
    uniqueDates.map(date => dateFilters.push({text: date, value: date}));

    const uniqueMaps = [...new Set(dataSource.map(file => file.sales_map))];
    let mapFilters = [];
    uniqueMaps.map(map => mapFilters.push({text: map, value: map}));

    const columns = [
      {
        title: 'File Name',
        dataIndex: 'file_name',
        key: 'file_name',
        sorter: (a, b) => a.file_name.localeCompare(b.file_name),
        sortDirections: ['descend', 'ascend'],
        render: (text, record) => <a href={"/pages/sales_details?id=" + record.key}>{text}</a>,
      },
      {
        title: 'File Template',
        dataIndex: 'sales_map',
        filters: mapFilters,
        onFilter:(value, record) => record.sales_map.indexOf(value) === 0,
        key: 'sales_map',
        sorter: (a, b) => a.sales_map.localeCompare(b.sales_map),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Status',
        dataIndex: 'status_display',
        sorter: (a, b) => a.status_display.localeCompare(b.status_display),
        sortDirections: ['descend', 'ascend'],
        key: 'status_display',
      },
      {
        title: 'Message',
        dataIndex: 'failure_message',
        sorter: (a, b) => a.rows.localeCompare(b.failure_message),
        sortDirections: ['descend', 'ascend'],
        key: 'failure_message',
      },
      {
        title: 'Imported',
        dataIndex: 'imported',
        filters: dateFilters,
        onFilter: (value, record) => record.imported.indexOf(value) === 0,
        sorter: (a, b) => new Date(a.imported) - new Date(b.imported),
        sortDirections: ['descend', 'ascend'],
        key: 'imported',
        render: (text) => moment(text).format('L'),
      },
      {
        title: 'Rows',
        dataIndex: 'rows',
        sorter: (a, b) => a.rows - b.rows,
        sortDirections: ['descend', 'ascend'],
        key: 'rows',
      },
      {
        title: 'Errors',
        dataIndex: 'failed_rows',
        sorter: (a, b) => a.failed_rows - b.failed_rows,
        sortDirections: ['descend', 'ascend'],
        key: 'failed_rows',
      },
      {
        title: 'Units',
        dataIndex: 'units',
        sorter: (a, b) => a.units - b.units,
        sortDirections: ['descend', 'ascend'],
        key: 'units',
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        sorter: (a, b) => a.amount - b.amount,
        sortDirections: ['descend', 'ascend'],
        key: 'amount',
        render: (text) => parseFloat(text).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
      },
      {
        title: '', dataIndex: '', key: 'x', render: (text, record) =>
        <span>
          <Tooltip title="Download original file">
            <Button shape="circle" icon={<DownloadOutlined />} href={"/revenue_files/" + record.key} download />
          </Tooltip>
          <Tooltip title="Delete the file and data">
            <Button shape="circle" icon={<DeleteOutlined />} style={{color: 'red'}} onClick={() => this.requestDeleteRevenueFile(record.key)}
                  disabled={this.actionDisabled(record.status)} />
          </Tooltip>
          <Tooltip title="Download formatted file">
            <Button shape="circle" icon={<DownloadOutlined />} href={"/revenues.csv?revenue_file_uuid=" + record.key + "&start_date=" + null + "&end_date=" + null} download
                  disabled={this.actionDisabled(record.status)} />
          </Tooltip>
          <Tooltip title={this.sendToRTText(record.status)}>
            <Button shape="circle" loading={this.isSendingToRT(record.status)} icon={ <ArrowRightOutlined /> }  onClick={() => this.sendToRoyaltyTracker(record.key)}
                  disabled={this.actionDisabled(record.status) || !this.canSendToRT(record.status)} />
          </Tooltip>
        </span>
      },
    ];

    return(
      <div className='revenue-file'>
        {this.state.alertVisible ? this.alertWindow() : null}

        <Button href="import_sales" type="primary"><Icon type="upload"/>Import Sales File</Button>

        <Table columns={columns}
               loading={this.state.loading}
               dataSource={dataSource}
               size='middle'
               scroll={{ x: 1400 }}
        />
      </div>
    );
  }
}

export default RevenueFiles
