import React from 'react';
import { FormControl } from "react-bootstrap";
import { CURRENCIES } from '../../constants';

class CurrencyCodeChooser extends React.Component {
  constructor(props) {
    super(props);
  }

  optionValues(name, index) {
    return <option key={index} value={index}>{name}</option>
  }

  render() {
    return (
      <FormControl
        componentClass="select"
        defaultValue={this.props.defaultValue}
        value={this.props.value}
        onChange={this.props.onChange}
      >
        {this.props.blank && <option hidden key="blank" value="">{this.props.blank}</option>}
        {Object.keys(CURRENCIES).map((key) => this.optionValues(`${key} (${CURRENCIES[key]})`, key))}
      </FormControl>
    );
  }
}

export default CurrencyCodeChooser;
