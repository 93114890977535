import React from "react";
import { Button, Well } from "react-bootstrap";

class SalesMappingStep11a extends React.Component {
  constructor(props) {
    super(props);

    this.currencyCodeInFile = this.currencyCodeInFile.bind(this);
    this.currencyNotInFile = this.currencyNotInFile.bind(this);
  }

  currencyCodeInFile() {
    this.props.setCurrencyInFile(true);
  }

  currencyNotInFile() {
    this.props.setCurrencyInFile(false);
  }

  render() {
    const { currencyConversion, currencyInFile } = this.props;

    if (currencyConversion === true) {
      return (
        <Well>
          <div>
            Is the entire file in a single currency, or are there multiple
            currencies specified in the file? If there are multiple currencies,
            we assume that a currency code is present.
            <br /> &nbsp;
            <div>
              <Button
                key="assignCurrencyCodeInFileYes"
                bsSize="small"
                bsStyle={currencyInFile === true ? "primary" : "default"}
                onClick={this.currencyCodeInFile}
              >
                The file has multiple currencies
              </Button>
              &nbsp;
              <Button
                key="assignCurrencyCodeInFileNo"
                bsSize="small"
                bsStyle={currencyInFile === false ? "primary" : "default"}
                onClick={this.currencyNotInFile}
              >
                The entire file has the same currency
              </Button>
            </div>
          </div>
        </Well>
      );
    } else {
      return <div></div>;
    }
  }
}

export default SalesMappingStep11a;
