import React from "react";
import { Button, Panel, Well, FormControl } from "react-bootstrap";
import InputColumn from "../InputColumn";
import { INVALID_COLUMN_NUMBER, VALUE_NONE } from "../../../constants";

class SalesMappingStep8 extends React.Component {
  constructor(props) {
    super(props);

    this.allowedToMoveOn = this.allowedToMoveOn.bind(this);
    this.renderAssignColumn = this.renderAssignColumn.bind(this);
    this.renderAssignNetUnitsSoldField = this.renderAssignNetUnitsSoldField.bind(this);
    this.setColumnValue = this.setColumnValue.bind(this);
    this.setFieldValue = this.setFieldValue.bind(this);
    this.skipSelection = this.skipSelection.bind(this);
  }

  componentDidMount() {
    const { netUnitsColumn } = this.props;

    if (netUnitsColumn != null) {
      this.props.setHighlightColumn(parseInt(netUnitsColumn, 10));
    } else {
      this.props.setHighlightColumn(null);
    }
  }

  allowedToMoveOn() {
    return (
      INVALID_COLUMN_NUMBER !== this.props.netUnitsColumn ||
      "" !== this.props.netUnitsField
    );
  }

  addUpdateButton() {
    if (this.props.editingMapping) {
      return (
        <Button
          key="update"
          bsStyle="primary"
          bsSize="small"
          onClick={this.props.assignNamePage}
        >
          Finish Editing and Update
        </Button>
      );
    }
  }

  setColumnValue(event) {
    if (VALUE_NONE === event.target.value) {
      this.props.setNetUnitsSoldColumn(event.target.value);
    } else {
      this.props.setNetUnitsSoldColumn(parseInt(event.target.value, 10));
    }
  }

  setFieldValue(event) {
    this.props.setNetUnitsSoldField(event.target.value);
  }

  renderAssignColumn() {
    if (this.props.assignNetUnitsSoldColumn) {
      return (
        <Well>
          <p>Which column contains the Net Units Sold?</p>

          <InputColumn
            columnNames={this.props.columnNames}
            value={this.props.netUnitsColumn}
            setColumn={this.setColumnValue}
            addNoneSelection={false}
          />

          <p>
            Upon picking a column, it will appear highlighted below. Please
            confirm your selection and click Next.
          </p>
        </Well>
      );
    } else {
      return <div></div>;
    }
  }

  renderAssignNetUnitsSoldField() {
    if (this.props.assignNetUnitsSoldField) {
      return (
        <Well>
          <p>What Net Units Sold value do you want to assign?</p>

          <FormControl
            key="netUnitsSoldField"
            type="text"
            onChange={this.setFieldValue}
            label="Net Units Sold"
            defaultValue={this.props.netUnitsField}
          />

          <p>Please confirm your selection and click Next.</p>
        </Well>
      );
    } else {
      return <div></div>;
    }
  }

  skipSelection(){
    this.props.skipNetUnitsSold();
    this.props.nextPage();
  }

  render() {
    const {
      setAssignNetUnitsSoldColumn, setAssignNetUnitsSoldField, assignNetUnitsColumn, assignNetUnitsField
    } = this.props;

    return (
      <Panel
        header={
          <div>
            <div>
              Net Units Sold
              <div className="pull-right">{this.addUpdateButton()}</div>
            </div>
          </div>
        }
      >
        <div>
          <p>
            How do you want to assign the net units sold? This field is optional. Click the Skip button if that does not apply to this file.
          </p>
          <p>
            <Button
              key="netUnitsFromColumn"
              bsSize="small"
              onClick={ setAssignNetUnitsSoldColumn }
              bsStyle={ assignNetUnitsColumn === true ? "primary" : "default" }
            >
              Assign Net Units Sold Based on Column
            </Button>
            <Button
              key="netUnitsForWholeFile"
              bsSize="small"
              onClick={ setAssignNetUnitsSoldField }
              bsStyle={ assignNetUnitsField === true ? "primary" : "default" }
            >
              Assign Net Units Sold for All Entries in File
            </Button>
          </p>
        </div>

        {this.renderAssignColumn()}
        {this.renderAssignNetUnitsSoldField()}

        <div className="pull-left">
          <Button
            key="previousPage"
            bsStyle="primary"
            className="pull-right"
            onClick={this.props.previousPage}
          >
            Back
          </Button>
        </div>
        <div className="pull-right">
          <Button key="skip" bsStyle="warning" onClick={this.skipSelection}>
            Skip
          </Button>
          <Button
            key="nextPage"
            bsStyle="primary"
            disabled={!this.allowedToMoveOn()}
            onClick={this.props.nextPage}
          >
            Next
          </Button>
        </div>
      </Panel>
    );
  }
}

export default SalesMappingStep8;
