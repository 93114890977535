import Moment from "moment";

export default function defaultDateFormatter(date) {

  if (date instanceof Date) {
    return date ? `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}` : "";
  } else {
      if (date instanceof Moment) {
        return date ? `${date.month() + 1}/${date.date()}/${date.year()}` : "";
      } else if (typeof(date) === 'string'){
        let string_date = new Date(date);
        return date ? `${string_date.getMonth() + 1}/${string_date.getDate()}/${string_date.getFullYear()}` : "";
      }
  }
}
