import React from "react";
import {FormControl, Well} from "react-bootstrap";
import InputColumn from "../InputColumn";
import {DATE_FORMATS} from "../../../constants";

class SalesMappingStep3a extends React.Component {
  constructor(props) {
    super(props);
  }

  optionValues(name, value){
    return <option key={value} value={value}>{name}</option>
  }

  render() {
    const { assignDate } = this.props;

    if (assignDate === true) {
      return (
        <div>
          We will allow you to assign a date at the time of import. Click the
          next button to move on.
          <br />
          &nbsp;
        </div>
      );
    } else {
      if (assignDate === false) {
        return (
          <div>
            <Well>
              Please select the date column below.
              <br /> &nbsp;
              <InputColumn
                columnNames={this.props.columnNames}
                value={this.props.dateColumn}
                setColumn={this.props.setTransactionDateColumn}
                addNoneSelection={false}
              />
              <p>
                Upon picking a column, it will appear highlighted below. Please
                confirm your selection and click Next.
              </p>
            </Well>
            <Well>
              Please select format of the date in the file.
              <br /> &nbsp;
              <FormControl
                componentClass="select"
                defaultValue={this.props.dateFormat}
                onChange={this.props.setDateFormat}
              >
                {Object.entries(DATE_FORMATS).map(([value, name]) => this.optionValues(name, value))}
              </FormControl>
            </Well>
          </div>
        );
      } else {
        return <div></div>;
      }
    }
  }
}

export default SalesMappingStep3a;
