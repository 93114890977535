import React from "react";
import { Button, Panel, Well, FormControl } from "react-bootstrap";
import InputColumn from "../InputColumn";
import { VALUE_NONE } from "../../../constants";

class SalesMappingSkipRow extends React.Component {
  constructor(props) {
    super(props);

    this.toggleUseSkipRowProcessor = this.toggleUseSkipRowProcessor.bind(this);
    this.setSkipMatchColumn = this.setSkipMatchColumn.bind(this);
    this.setSkipMatchExpression = this.setSkipMatchExpression.bind(this);
    this.allowedToMoveOn = this.allowedToMoveOn.bind(this);
    this.renderSkipColumn = this.renderSkipColumn.bind(this);
    this.renderSkipExpression = this.renderSkipExpression.bind(this);
  }

  allowedToMoveOn() {
    return (
      !this.props.useSkipRowProcessor ||
        this.props.skipMatchColumn && (this.props.skipMatchColumn !== VALUE_NONE) &&
        this.props.skipMatchExpression
    );
  }

  toggleUseSkipRowProcessor(_event) {
    this.props.setUseSkipRowProcessor(!this.props.useSkipRowProcessor);
  }

  setSkipMatchColumn(event) {
    const rawValue = event.target.value;
    const newValue = (VALUE_NONE === rawValue) ? rawValue : parseInt(rawValue, 10);

    this.props.setColumn("skipMatchColumn", newValue);
  }

  setSkipMatchExpression(event) {
    this.props.setSkipMatchExpression(event.target.value);
  }

  addUpdateButton() {
    if (this.props.editingMapping) {
      return (
        <Button
          key="update"
          bsStyle="primary"
          bsSize="small"
          onClick={this.props.assignNamePage}
        >
          Finish Editing and Update
        </Button>
      );
    }
  }

  renderSkipColumn() {
    if (this.props.useSkipRowProcessor) {
      return (
        <Well>
          <p>Which column contains the data to be checked?</p>

          <InputColumn
            columnNames={this.props.columnNames}
            value={this.props.skipMatchColumn}
            setColumn={this.setSkipMatchColumn}
            addNoneSelection={true}
          />

          <p>
            Upon picking a column, it will appear highlighted below. Please
            confirm your selection and click Next.
          </p>
        </Well>
      );
    } else {
      return <div></div>;
    }
  }

  renderSkipExpression() {
    const { useSkipRowProcessor, skipMatchColumn } = this.props;

    if (useSkipRowProcessor && skipMatchColumn && (VALUE_NONE !== skipMatchColumn)) {
      return (
        <Well>
          <p>What value in the selected column indicates that the row should be skipped?</p>

          <FormControl
            key="skipMatchExpressionField"
            type="text"
            onChange={this.setSkipMatchExpression}
            label="Skip Value"
            defaultValue={this.props.skipMatchExpression}
          />

          <p>Please confirm your selection and click Next.</p>
        </Well>
      );
    } else {
      return <div></div>;
    }
  }

  render() {
    const { useSkipRowProcessor, skipMatchColumn, skipMatchExpression } = this.props;

    return (
      <Panel
        header={
          <div>
            <div>
              Skip Row Feature
              <div className="pull-right">{this.addUpdateButton()}</div>
            </div>
          </div>
        }
      >
        <div>
          <p>
            Sales Aggregator can skip rows that have a specific value in
            a selected column. Skipped rows will not be processed as revenue.
            Do you want to use the skip row feature?
          </p>
          <Button
            key="useSkipRowProcessor"
            bsSize="small"
            onClick={this.toggleUseSkipRowProcessor}
            bsStyle={useSkipRowProcessor ? "primary" : "default"}
          >
            Use the skip row feature
          </Button>
          <br /> &nbsp;
          {this.renderSkipColumn()}
          {this.renderSkipExpression()}
        </div>

        <div className="pull-left">
          <Button
            key="previousPage"
            bsStyle="primary"
            className="pull-right"
            onClick={this.props.previousPage}
          >
            Back
          </Button>
        </div>
        <div className="pull-right">
          <Button
            key="nextPage"
            bsStyle="primary"
            disabled={!this.allowedToMoveOn()}
            onClick={this.props.nextPage}
          >
            Next
          </Button>
        </div>
      </Panel>
    );
  }
}

export default SalesMappingSkipRow;
