import React from "react";
import { Well, Button } from "react-bootstrap";
import InputColumn from "../InputColumn";
import { DEDUCTION_TYPE_FILE } from "../../../constants";

class SalesMappingStep12b extends React.Component {

  render() {
    const { deductionType } = this.props;

    if (DEDUCTION_TYPE_FILE === deductionType) {
      return (
        <Well>
          What column is the deduction column?
          <InputColumn
            columnNames={this.props.columnNames}
            value={this.props.deductionColumn}
            setColumn={this.props.setColumn}
            addNoneSelection={false}
          />
          <div>
            We will deduct the amount in the column above. How should we apply
            the Deduction's amount?
          </div>
          <div>
            <Button
              key="negativeDeductionSignPositive"
              bsSize="small"
              onClick={this.props.negativeDeductionSignPositive}
              bsStyle="default"
            >
              Subtract it from the Net Amount column's value
            </Button>
            &nbsp;
            <Button
              key="negativeDeductionSignNegative"
              bsSize="small"
              onClick={this.props.negativeDeductionSignNegative}
              bsStyle="default"
            >
              Add it to Net Amount column's value
            </Button>
          </div>
        </Well>
      );
    } else {
      return <div></div>;
    }
  }
}

export default SalesMappingStep12b;
