import React from "react";
import { Button, Panel } from "react-bootstrap";

class SalesMappingStep1 extends React.Component {
  constructor(props) {
    super(props);

    this.displayFileName = this.displayFileName.bind(this);
  }

  displayFileName() {
    return (
      <p>
        <strong>You have selected {this.props.fileName}</strong>
      </p>
    );
  }

  render() {
    return (
      <Panel header={<div>Sample File</div>}>
        <div>
          <p>
            First, let's import a sample sales file for the template we are
            creating. This will allow you to reference this file when creating
            your File Template. Click "Choose File" below and select your file.
            A grid will appear below showing the first few lines of the file.
          </p>

          {this.props.fileName ? this.displayFileName() : null}

          <input
            type="file"
            name="File Upload"
            id="fileInput"
            onChange={this.props.parseData}
            accept=".csv,.xlsx,.xls,.ods,.txt,.tsv"
          />
          <br />
          <p>Once this is done, click Next.</p>
        </div>

        <div className="pull-right">
          <Button
            key="nextPage"
            bsStyle="primary"
            disabled={!this.props.fileUploaded}
            onClick={this.props.nextPage}
          >
            Next
          </Button>
        </div>
      </Panel>
    );
  }
}

export default SalesMappingStep1;
