import React from "react";
import { Button, Panel, Well, FormControl } from "react-bootstrap";
import InputColumn from "../InputColumn";
import { INVALID_COLUMN_NUMBER, VALUE_NONE } from "../../../constants";

class SalesMappingStepAccountName extends React.Component {
  constructor(props) {
    super(props);

    this.skipAccountName = this.skipAccountName.bind(this);
    this.allowedToMoveOn = this.allowedToMoveOn.bind(this);
    this.renderAssignColumn = this.renderAssignColumn.bind(this);
    this.setColumnValue = this.setColumnValue.bind(this);
    this.setFieldValue = this.setFieldValue.bind(this);
  }

  componentDidMount() {
    const { accountNameColumn } = this.props;

    if (accountNameColumn != null) {
      this.props.setHighlightColumn(parseInt(accountNameColumn, 10));
    } else {
      this.props.setHighlightColumn(null);
    }
  }

  allowedToMoveOn() {
    return (
      INVALID_COLUMN_NUMBER !== this.props.accountNameColumn ||
      "" !== this.props.accountNameField
    );
  }

  addUpdateButton() {
    if (this.props.editingMapping) {
      return (
        <Button
          key="update"
          bsStyle="primary"
          bsSize="small"
          onClick={this.props.assignNamePage}
        >
          Finish Editing and Update
        </Button>
      );
    }
  }

  skipAccountName() {
    const { skipAccountName, nextPage } = this.props;

    skipAccountName();
    nextPage();
  }

  addSkipButton() {
    return (
      <Button key="skip" bsStyle="warning" onClick={this.skipAccountName}>
        Skip
      </Button>
    );
  }

  setColumnValue(event) {
    if (VALUE_NONE === event.target.value) {
      this.props.setAccountNameColumn(event.target.value);
    } else {
      this.props.setAccountNameColumn(parseInt(event.target.value, 10));
    }
  }

  setFieldValue(event) {
    this.props.setAccountNameField(event.target.value);
  }

  renderAssignColumn() {
    if (this.props.assignAccountNameColumn) {
      return (
        <Well>
          <p>Which column contains the Account Name?</p>

          <InputColumn
            columnNames={this.props.columnNames}
            value={this.props.accountNameColumn}
            setColumn={this.setColumnValue}
            addNoneSelection={false}
          />

          <p>
            Upon picking a column, it will appear highlighted below. Please
            confirm your selection and click Next.
          </p>
        </Well>
      );
    } else {
      return <div></div>;
    }
  }

  renderAssignAccountName() {
    if (this.props.assignAccountNameField) {
      return (
        <Well>
          <p>What Account Name do you want to assign?</p>

          <FormControl
            key="accountNameField"
            type="text"
            onChange={this.setFieldValue}
            label="Account Name"
            defaultValue={this.props.accountNameField}
          />

          <p>Please confirm your selection and click Next.</p>
        </Well>
      );
    } else {
      return <div></div>;
    }
  }

  render() {
    const {
      setAssignAccountNameColumn, setAssignAccountNameField,
      assignAccountNameColumn, assignAccountNameField
    } = this.props;

    return (
      <Panel
        header={
          <div>
            <div>
              Account Name
              <div className="pull-right">{this.addUpdateButton()}</div>
            </div>
          </div>
        }
      >
        <div>
          <p>
            Sales Aggregator can apply a Account Name to each sale in the file,
            assign the Account Name found in the file, or leave the Account Name
            column blank.
          </p>
          <p>
            <Button
              key="accountNameFromColumn"
              bsSize="small"
              onClick={ setAssignAccountNameColumn }
              bsStyle={ assignAccountNameColumn === true ? "primary" : "default" }
            >
              Assign Account Name Based on Column
            </Button>
            <Button
              key="accountNameFromWholeFile"
              bsSize="small"
              onClick={ setAssignAccountNameField }
              bsStyle={ assignAccountNameField === true ? "primary" : "default" }
            >
              Assign Account Name for All Entries in File
            </Button>
          </p>
        </div>

        {this.renderAssignColumn()}
        {this.renderAssignAccountName()}

        <div className="pull-left">
          <Button
            key="previousPage"
            bsStyle="primary"
            className="pull-right"
            onClick={this.props.previousPage}
          >
            Back
          </Button>
        </div>
        <div className="pull-right">
          {this.addSkipButton()}
          <Button
            key="nextPage"
            bsStyle="primary"
            disabled={!this.allowedToMoveOn()}
            onClick={this.props.nextPage}
          >
            Next
          </Button>
        </div>
      </Panel>
    );
  }
}

export default SalesMappingStepAccountName;
