import React from "react";
import { Button, Panel } from "react-bootstrap";
import SalesMappingStep11a from "./SalesMappingStep11a";
import SalesMappingStep11b from "./SalesMappingStep11b";
import SalesMappingStep11c from "./SalesMappingStep11c";
import SalesMappingStep11d from "./SalesMappingStep11d";
import SalesMappingStep11e from "./SalesMappingStep11e";
import SalesMappingStep11f from "./SalesMappingStep11f";
import SalesMappingStep11g from "./SalesMappingStep11g";
import { INVALID_COLUMN_NUMBER } from "../../../constants";

class SalesMappingStep11 extends React.Component {
  constructor(props) {
    super(props);

    this.specifyCurrencyDateTrue = this.specifyCurrencyDateTrue.bind(this);
    this.specifyCurrencyDateFalse = this.specifyCurrencyDateFalse.bind(this);
    this.allowedToMoveOn = this.allowedToMoveOn.bind(this);
    this.setCurrencyCodeColumn = this.setCurrencyCodeColumn.bind(this);
    this.setTargetCurrencyCode = this.setTargetCurrencyCode.bind(this);
  }
  componentDidMount() {
    const { currencyCodeColumn } = this.props;

    if (currencyCodeColumn != null) {
      this.props.setHighlightColumn(parseInt(currencyCodeColumn, 10));
    } else {
      this.props.setHighlightColumn(null);
    }
  }

  allowedToMoveOn() {
    const isValidColumn = (column) => {
      return this.props[column] != undefined && this.props[column] !== INVALID_COLUMN_NUMBER;
    }

    const currencyCodeSet = this.props.specifyCurrencyDate !== null;

    const currencyDateSet =
      (this.props.currencyInFile === true &&
        isValidColumn('currencyCodeColumn')) ||
      (this.props.currencyInFile === false &&
        this.props.conversionRate !== null);

    const currencyBasedOnRate = this.props.currencyInFile === true &&
                                this.props.conversionRate &&
                                isValidColumn('currencyCodeColumn');

    const currencyBasedOnTarget = this.props.currencyInFile === true &&
                                  !this.props.conversionRate &&
                                  isValidColumn('currencyCodeColumn') &&
                                  isValidColumn('targetCurrencyCode');

    const allCurrencyConversionParametersSet =
      this.props.currencyConversion === true &&
        (!this.props.currencyInFile || currencyBasedOnRate || currencyBasedOnTarget) &&
        (this.props.conversionRate || currencyCodeSet) &&
        currencyDateSet;

    const noCurrencyConversion = this.props.currencyConversion === false;

    return noCurrencyConversion || allCurrencyConversionParametersSet;
  }

  specifyCurrencyDateTrue() {
    this.props.setSpecifyCurrencyDate(true);
  }

  specifyCurrencyDateFalse() {
    this.props.setSpecifyCurrencyDate(false);
  }

  setCurrencyCodeColumn(event) {
    this.props.setColumn(
      "currencyCodeColumn",
      parseInt(event.target.value, 10)
    );
  }

  setTargetCurrencyCode(event) {
    this.props.setTargetCurrencyCode(event.target.value);
  }

  addUpdateButton() {
    if (this.props.editingMapping) {
      return (
        <Button
          key="update"
          bsStyle="primary"
          bsSize="small"
          onClick={this.props.assignNamePage}
        >
          Finish Editing and Update
        </Button>
      );
    }
  }

  render() {
    const { currencyConversion } = this.props;

    return (
      <Panel
        header={
          <div>
            <div>
              Currency Conversion
              <div className="pull-right">{this.addUpdateButton()}</div>
            </div>
          </div>
        }
      >
        <div>
          <p>
            Does this file contain amounts in other currencies, and if so, would
            you like Sales Aggregator to convert the currency for you?
          </p>
          <Button
            key="currencyYes"
            bsSize="small"
            onClick={() => this.props.setCurrencyConversion(true)}
            bsStyle={currencyConversion === true ? "primary" : "default"}
          >
            Yes
          </Button> &nbsp;
          <Button
            key="currencyNo"
            bsSize="small"
            onClick={() => this.props.setCurrencyConversion(false)}
            bsStyle={currencyConversion === false ? "primary" : "default"}
          >
            No
          </Button>
        </div>
        <br /> &nbsp;
        <div>
          <SalesMappingStep11a
            currencyConversion={this.props.currencyConversion}
            setCurrencyInFile={this.props.setCurrencyInFile}
            currencyInFile={this.props.currencyInFile}
          />
        </div>
        <div>
          <SalesMappingStep11f
            setCurrencyInFile={this.props.setCurrencyInFile}
            currencyInFile={this.props.currencyInFile}
            conversionRate={this.props.conversionRate}
            setConversionRate={this.props.setConversionRate}
          />
        </div>
        <div>
          <SalesMappingStep11b
            currencyConversion={this.props.currencyConversion}
          />
        </div>
        <div>
          <SalesMappingStep11c
            columnNames={this.props.columnNames}
            value={this.props.currencyCodeColumn}
            currencyConversion={this.props.currencyConversion}
            currencyInFile={this.props.currencyInFile}
            setCurrencyCodeColumn={this.setCurrencyCodeColumn}
            conversionRate={this.props.conversionRate}
          />
        </div>
        <div>
          <SalesMappingStep11g
            columnNames={this.props.columnNames}
            value={this.props.targetCurrencyCode}
            currencyConversion={this.props.currencyConversion}
            currencyInFile={this.props.currencyInFile}
            setTargetCurrencyCode={this.setTargetCurrencyCode}
            conversionRate={this.props.conversionRate}
          />
        </div>
        <div>
          <SalesMappingStep11d
            specifyCurrencyDateTrue={this.specifyCurrencyDateTrue}
            specifyCurrencyDateFalse={this.specifyCurrencyDateFalse}
            currencyConversion={this.props.currencyConversion}
            specifyCurrencyDate={this.props.specifyCurrencyDate}
            conversionRate={this.props.conversionRate}
          />
        </div>
        <div>
          <SalesMappingStep11e
            specifyCurrencyDate={this.props.specifyCurrencyDate}
          />
        </div>
        <div className="pull-left">
          <Button
            key="previousPage"
            bsStyle="primary"
            className="pull-right"
            onClick={this.props.previousPage}
          >
            Back
          </Button>
        </div>
        <div className="pull-right">
          <Button
            key="nextPage"
            bsStyle="primary"
            onClick={this.props.nextPage}
            disabled={!this.allowedToMoveOn()}
          >
            Next
          </Button>
        </div>
      </Panel>
    );
  }
}

export default SalesMappingStep11;
