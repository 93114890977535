import React from 'react';
import { Tooltip, Button } from 'antd';
import Icon from '@ant-design/icons';
import { FormControl } from "react-bootstrap";
import { isEqual, debounce } from "lodash";
import InputColumn, { numberToColumnName } from '../../SalesMappingTool/Mapping/InputColumn';

export const REVENUE_TYPE_KEY = 'Revenue Type';

export default class LookupTable extends React.Component {
  constructor(props) {
    super(props);

    const revenue_type_lookup_columns = props.lookupColumns && props.lookupColumns.find(({ key }) => key === REVENUE_TYPE_KEY);
    const { lookup_key_columns, lookup_key_values } = revenue_type_lookup_columns ? revenue_type_lookup_columns : {};

    this.addLookupKeyColumn = this.addLookupKeyColumn.bind(this);
    this.removeLookupKeyColumn = this.removeLookupKeyColumn.bind(this);
    this.changeLookupKeyColumn = this.changeLookupKeyColumn.bind(this);
    this.addLookupKeyValues = this.addLookupKeyValues.bind(this);
    this.removeLookupKeyValues = this.removeLookupKeyValues.bind(this);

    this.state = {
      lookupKeyColumns: lookup_key_columns || [0],
      lookupKeyValues: lookup_key_values ? Object.entries(lookup_key_values).map(([key, value]) => ({
        lookup: key.split(','),
        value: value,
        valid: true,
      })) : [{ lookup: [], value: '', valid: true }],
    };
  }

  componentDidUpdate(_prevProps, prevState) {
    if (
      isEqual(prevState.lookupKeyColumns, this.state.lookupKeyColumns) &&
      isEqual(prevState.lookupKeyValues, this.state.lookupKeyValues)
    ) {
      return;
    }

    this.updateData();
  }

  addLookupKeyColumn = () => {
    const { lookupKeyColumns } = this.state;
    const newLookupKeyColumn = [...lookupKeyColumns];

    newLookupKeyColumn.push(parseInt(lookupKeyColumns[lookupKeyColumns.length - 1]) + 1);
    this.setState({ lookupKeyColumns: newLookupKeyColumn });
  }

  removeLookupKeyColumn = (index) => {
    const { lookupKeyColumns } = this.state;
    const newLookupKeyColumn = [...lookupKeyColumns];

    newLookupKeyColumn.splice(index, 1);
    this.setState({ lookupKeyColumns: newLookupKeyColumn });
  }

  changeLookupKeyColumn = (index, value) => {
    const { lookupKeyColumns } = this.state;
    const newLookupKeyColumn = [...lookupKeyColumns];

    newLookupKeyColumn[index] = parseInt(value, 10);
    this.setState({ lookupKeyColumns: newLookupKeyColumn }, this.updateData);
  }

  addLookupKeyValues = () => {
    const { lookupKeyValues } = this.state;
    const newLookupKeyValues = [...lookupKeyValues];

    const newKeyValue = { lookup: [], value: '', valid: true };
    newLookupKeyValues.push(newKeyValue);
    this.setState({ lookupKeyValues: newLookupKeyValues });
  }

  removeLookupKeyValues = (index, callback) => {
    const { lookupKeyValues } = this.state;
    const newLookupKeyValues = [...lookupKeyValues];

    newLookupKeyValues.splice(index, 1);
    this.setState({ lookupKeyValues: newLookupKeyValues }, callback);
  }

  changeLookupKeyValues = (row, column, value) => {
    const { lookupKeyColumns, lookupKeyValues } = this.state;
    const newLookupKeyValues = [...lookupKeyValues];

    if (column >= lookupKeyColumns.length) {
      newLookupKeyValues[row].value = value;
    } else {
      newLookupKeyValues[row].lookup[column] = value;
    }

    this.setState({ lookupKeyValues: newLookupKeyValues }, this.updateData);
  }

  copyFromExcel = (event, index) => {
    const clipboardText = event.clipboardData.getData('text');
    const rows = clipboardText.split(/\r?\n/).reverse();
    const columnsLength = rows[0] && rows[0].split(/[\t,;]/).length;

    if (columnsLength <= 1) return;

    this.removeLookupKeyValues(index, () => {
      const { lookupKeyColumns, lookupKeyValues } = this.state;

      rows.forEach((row) => {
        const lookup = row.split(/[\t,;]/);
        const value = lookup.pop();

        lookupKeyValues.splice(index, 0, { lookup, value });
      })

      if (rows[0] && columnsLength > lookupKeyColumns.length) {
        const extraColumns = new Array(columnsLength - lookupKeyColumns.length - 1).fill(0);
        const newLookupKeyColumns = lookupKeyColumns.concat(extraColumns);

        this.setState({ lookupKeyValues, lookupKeyColumns: newLookupKeyColumns });
      } else {
        this.setState({ lookupKeyValues });
      }
    });
  }

  updateData() {
    const { setLookupColumns, setAllowedToMoveOn } = this.props;
    const { lookupKeyColumns, lookupKeyValues } = this.state;
    const newLookupKeyValues = [...lookupKeyValues];
    let values = [];

    for (let i = 0; i < newLookupKeyValues.length; i++) {
      newLookupKeyValues[i].valid = false;
      newLookupKeyValues[i].lookup = newLookupKeyValues[i].lookup.slice(0, lookupKeyColumns.length);

      values.push(JSON.stringify(newLookupKeyValues[i].lookup));
    }

    const uniqueValues = [...new Set(values)];

    for (const value of uniqueValues) {
      const index = values.findIndex(a => a === value);

      newLookupKeyValues[index].valid = true;
    }

    this.setState({ lookupKeyValues: newLookupKeyValues });

    const newData = [{
      key: REVENUE_TYPE_KEY,
      lookup_key_columns: lookupKeyColumns,
      lookup_key_values: newLookupKeyValues.reduce((acc, cur) => {
        if (cur.valid) {
          const lookupKey = cur.lookup.join(',');
          acc[lookupKey] = cur.value;
        }

        return acc;
      }, {})
    }];

    setLookupColumns(newData);
    setAllowedToMoveOn(newLookupKeyValues.every(item => item.valid && item.value));
  }

  render() {
    const { columnNames } = this.props;
    const { lookupKeyColumns, lookupKeyValues } = this.state;

    return (
      <div>
        <div className="ant-table ant-table-middle">
          <div className="ant-table-content">
            <div className="ant-table-body">
              <table style={{ maxWidth: 750 }}>
                <tbody className="ant-table-tbody">
                  {lookupKeyColumns.map((item, index) => (
                    <tr key={index} className="ant-table-row">
                      <td>
                        <InputColumn
                          columnNames={columnNames}
                          value={item || 0}
                          setColumn={(e) => this.changeLookupKeyColumn(index, e.target.value)}
                        />
                      </td>
                      <td align="left" width="170">
                        {lookupKeyColumns.length > 1 && <Button type="danger" onClick={() => this.removeLookupKeyColumn(index)}>Remove</Button>}
                        {index === lookupKeyColumns.length - 1 && (
                          <Button type="primary" onClick={this.addLookupKeyColumn} style={{ marginLeft: "10px" }}>Add</Button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <p className="ant-form-extra">
          Upon picking a column, it will appear highlighted below.
        </p>

        <p>Define the lookup table:</p>

        <div className="ant-table ant-table-middle">
          <div className="ant-table-content">
            <div className="ant-table-body">
              <table>
                <thead className="ant-table-thead">
                  <tr>
                    {lookupKeyColumns.map((item, index) => (
                      <th key={index}>{numberToColumnName(parseInt(item, 10), columnNames[item])}</th>
                    ))}
                    <th>Revenue Type to Use</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="ant-table-tbody">
                  {lookupKeyValues.map((item, rowIndex) => {
                    const invalid = !lookupKeyValues[rowIndex].valid;
                    const items = lookupKeyColumns.map((_, index) => (
                      <td key={index}>
                        <FormControl
                          type="text"
                          defaultValue={item.lookup[index] || ''}
                          onChange={debounce((e) => {
                            return this.changeLookupKeyValues(rowIndex, index, e.target.value)
                          }, 300)}
                          onPaste={(e) => this.copyFromExcel(e, rowIndex)}
                        />
                      </td>
                    ));

                    items.push(
                      <td key="lookup-value">
                        <FormControl
                          type="text"
                          defaultValue={item.value}
                          onChange={debounce((e) => {
                            return this.changeLookupKeyValues(rowIndex, lookupKeyColumns.length, e.target.value)
                          }, 300)}
                          onPaste={(e) => this.copyFromExcel(e, rowIndex)}
                        />
                      </td>
                    );

                    items.push(
                      <td key="lookup-action" className="lookup-action" width="170">
                        {lookupKeyValues.length > 1 && <Button type="danger" onClick={() => this.removeLookupKeyValues(rowIndex)}>Remove</Button>}
                        {rowIndex === lookupKeyValues.length - 1 && (
                          <Button type="primary" onClick={this.addLookupKeyValues} style={{ marginLeft: "10px" }}>Add</Button>
                        )}
                        {invalid && (
                          <Tooltip placement="topRight" title="Duplicated">
                            <Icon type="exclamation-circle" theme="twoTone" twoToneColor="#ff0000" />
                          </Tooltip>
                        )}
                      </td>
                    );

                    return <tr key={rowIndex} className={`ant-table-row${invalid && ' ant-alert-error'}`}>{items}</tr>;
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
